import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../../models/user.model';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  public user$: Observable<User> = this.authSvc.afAuth.user;
  click: boolean = false;

  public cedula: string;

  constructor(public authSvc: AuthService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    // this.cedula = this.route.snapshot.paramMap.get('id');
    // console.log(this.cedula);
  }

  // ngDoCheck(){
  //   this.cedula = this.route.snapshot.paramMap.get('id');
  //   console.log(this.cedula);
  // }
  
  


  async onLogout() {

    try {
      await this.authSvc.logout();
      this.router.navigate(['/inicio']);
    } catch (error) {
      console.log(error);
    }
    
  }

  showLogin() {
    this.click=true;
    // console.log(this.click);
   }

}
