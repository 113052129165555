<div id="login" class="container">
    <div id="contenedor" class="row">
      <div class="col-12 p-5">
        <div class="row">
          <div class="col-md-6 mx-auto mt-5">
            <div class="card">
              <div class="card-body">
                <h1 class="text-center">REGISTRO DE DOCTOR</h1>
                <form [formGroup]="registerForm" (ngSubmit)="onRegister()">
                  <div class="form-group">
                    <label for="email">Correo</label>
                    <input type="email" class="form-control" formControlName="email" />
                  </div>
                  <div class="form-group">
                    <label for="password">Contraseña</label>
                    <input type="password" class="form-control" formControlName="password"/>
                  </div>
                  <div class="form-group">
                      <label for="displayName">Nombre</label>
                      <input type="displayName" class="form-control" formControlName="displayName"/>
                  </div>
                  <div class="form-group">
                      <label for="cedula">Cedula</label>
                      <input type="cedula" class="form-control" formControlName="cedula"/>
                  </div>
                  <div class="form-group">
                      <label for="especialidad">Especialidad</label>
                      <input type="especialidad" class="form-control" formControlName="especialidad"/>
                  </div>
                  <button type="submit" class="btn btn-primary btn-lg btn-block">Registrar</button>
                </form>
              </div>
            </div>
          </div>
        </div>      
      </div>
    </div>
  </div>
