<div id="reporte-diario" class="container">
  <div class="row mt-5">
    <div class="col-12 p-5 my-5">
      <div id="contenedor" class="animated fadeIn faster row">
        <div class="pr-4 pt-3 col-9" id="imprimir" #imprimir>
          <div class="nowDateTime text-center">
            <h1>
              <span id="fecha" #fe></span><br />
            </h1>
          </div>
          <hr>
          <table class="table mt-3 animated fadeIn faster bg-secondary table-hover">
            <thead class="thead-dark">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Nombres</th>
                <th scope="col">Apellidos</th>
                <th scope="col">Cédula</th>
                <th scope="col">Diagnóstico</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let paciente of pacientes | filterDate:fechas; let i = index" [routerLink]="['/certificado-medico', paciente.id ]">
                <th scope="row">{{ i + 1 }}</th>
                <td>{{ paciente.nombres }}</td>
                <td>{{ paciente.apellidos }}</td>
                <td>{{ paciente.cedula }}</td>
                <td>{{ paciente.diagnostico }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="form-group col">
          <br>
          <button (click)="downloadPDF()" class="btn btn-primary ">Descargar Reporte</button>
        </div>
      </div>
    </div>
  </div>
</div>

