<div id="subsecuente" class="container">
  <div class="row my-5">
    <div class="col-12 pt-5 mt-5">
      <div id="contenedor" class="animated fadeIn faster p-3">
        <form class="container">
          <input class="form-control" name="buscarLetra" type="text" placeholder="Buscar paciente" (keyup)="mostrarRegistros(buscarTexto.value)" #buscarTexto [(ngModel)]="buscarLetra">
        </form>
        <div class="px-3">
          <table class="table mt-3 animated fadeIn faster bg-secondary table-hover" *ngIf="buscarNom===true">
            <thead class="thead-dark">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Nombres</th>
                <th scope="col">Apellidos</th>
                <th scope="col">Cédula</th>
                <th scope="col">Edad</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let paciente of pacientes | filter:buscarLetra; let i = index">
                <th scope="row">{{ i + 1 }}</th>
                <td>{{ paciente.nombres }}</td>
                <td>{{ paciente.apellidos }}</td>
                <td>{{ paciente.cedula }}</td>
                <td>{{ paciente.fecha_nacimiento | edad }}</td>
                <td>
                  <button class="btn btn-dark mr-1 mt-1" (click)="mostrarPrimera(paciente.id, paciente.nombres, paciente.apellidos, paciente.cedula)">
                    Primera
                  </button>
                  <button class="btn btn-dark mr-1 mt-1" (click)="buscarEvolucion(paciente.id)">
                    Subsecuente
                  </button>
                  <button class="btn btn-dark mr-2 mt-1" [routerLink]="['/certificado-medico', paciente.id ]">
                    <i class="far fa-file-alt mr-1"></i>
                    Certificado M.
                  </button>
                  <button class="btn btn-dark mt-1" [routerLink]="['/actualizar-paciente', paciente.id ]">
                    <i class="fa fa-pencil"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="buscarEvo===true" class="px-3">
          <br><br>
          <h1 class="text-center">{{ paciente.nombres }} {{ paciente.apellidos }}</h1>
          <div class="container">
            <label>Formularios</label>
            <select class="form-control" [(ngModel)]="opcionSeleccionado" (change)="change()">
              <option value="1"></option>
              <option value="f1">ATENCION A NIÑOS</option>
              <option value="f2">CONSULTA EXTERNA</option>
              <option value="f3">EMERGENCIA</option>
              <option value="f4">LABORATORIO CLINICO - SOLICITUD</option>
              <option value="f5">MATERNA PERINATAL</option>
              <option value="f6">ODONTOLOGIA</option>
              <option value="f7">REFERENCIA - CONTRAREFERENCIA</option>
              <option value="f8">SALUD OCUPACIONAL</option>
            </select>
          </div>
          <br><br>
          <div *ngIf="mostrarFrm===true">
            <embed [src]="urlPDF | safe" type="application/pdf" width="100%" height="1200" />
          </div>
          <br>
          <div class="row">
            <div class="col">
              <form (ngSubmit)="guardar( f )" #f="ngForm" *ngIf="buscarEvo===true">
                <div class="px-3" >
                  <h1>Nueva Evolución</h1>
                  <div class="left">
                    <div class="form-group">
                      <label> Fecha</label>
                      <input type="date"
                            class="form-control"
                            placeholder="Fecha"
                            [(ngModel)]="subsecuente.fecha"
                            name="fecha"
                            (change)="obtenerFecha(this)"
                            required>
                    </div>
                  </div>
                  <div class="right">
                    <div class="form-group">
                      <label>Hora</label>
                      <input type="time"
                            class="form-control"
                            [(ngModel)]="subsecuente.hora"
                            name="hora"
                            placeholder="Hora">
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Notas de Evolución</label>
                    <input type="text"
                          class="form-control"
                          [(ngModel)]="subsecuente.notas_evolucion"
                          name="notas_evolucion"
                          placeholder="Notas de Evolución">
                  </div>
                  <div class="form-group">
                    <label>Examen Fisico</label>
                    <input type="text"
                          class="form-control"
                          [(ngModel)]="subsecuente.farmacoterapia"
                          name="farmacoterapia"
                          placeholder="Examen Fisico">
                  </div>
                  <div class="form-group">
                    <label>Farmacoterapia e Indicacisones</label>
                    <input type="text"
                          class="form-control"
                          [(ngModel)]="subsecuente.farmacos"
                          name="farmacos"
                          placeholder="Farmacoterapia e Indicaciones">
                  </div>
                </div>
                <br>
                <div class="form-group px-3">
                  <button type="submit" class="btn btn-primary container">Agregar Evolución</button>
                </div>    
              </form>
            </div>
            <div class="col-7">
              <h1>Evolucion y Prescripciones</h1>
              <div id="imprimir" class="p-3 bg-white">
                <br>
                <div class="text-right">
                  <img src="../assets/img/logo-certificado-medico.png" width="30%">
                </div>
                <h1></h1>
                <br>
                <h4 class=""><strong>Paciente: </strong>{{ paciente.nombres }} {{ paciente.apellidos }}</h4>
                <table class="table mt-2 animated fadeIn faster bg-secondary  table-hover">
                  <thead class="thead-dark">
                    <tr>
                      <th scope="col">Fecha</th>
                      <th scope="col">Hora</th>
                      <th scope="col">Notas de Evolución</th>
                      <th scope="col">Examen Fisico</th>
                      <th scope="col">Farmacoterapia e Indicaciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let subsecuente of subsecuentes | filterEvo:identificador" (click)="editarEvolucion( subsecuente, i ) ">
                      <td>{{ subsecuente.fecha }}</td>
                      <td>{{ subsecuente.hora }}</td>
                      <td>{{ subsecuente.notas_evolucion }}</td>
                      <td>{{ subsecuente.farmacoterapia }}</td>
                      <td>{{ subsecuente.farmacos }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <br>
              <button (click)="downloadPDF()" class="btn btn-primary container">Descargar Evolución</button>
            </div>
          </div>
        </div>
        <div *ngIf="mostrarPri===true" class="px-3">
          <br><br>
          <h1 class="text-center">{{ paciente.nombres }} {{ paciente.apellidos }}</h1>
          <hr>
          <div class="container">
            <label>Formularios</label>
            <select class="form-control" [(ngModel)]="opcionSeleccionado2" (change)="changePrimera()">
              <option value="f1">ATENCION A NIÑOS</option>
              <option value="f2">CONSULTA EXTERNA</option>
              <option value="f3">EMERGENCIA</option>
              <option value="f4">LABORATORIO CLINICO - SOLICITUD</option>
              <option value="f5">MATERNA PERINATAL</option>
              <option value="f6">ODONTOLOGIA</option>
              <option value="f7">REFERENCIA - CONTRAREFERENCIA</option>
              <option value="f8">SALUD OCUPACIONAL</option>
            </select>
          </div>
          <br><br>
          <div>
            <div *ngIf="index == 'f1'">
              <embed src="../assets/files/Form1.pdf" type="application/pdf" width="100%" height="1200" />
            </div>
            <div *ngIf="index == 'f2'">
              <embed src="../assets/files/Form2.pdf" type="application/pdf" width="100%" height="1200" />
            </div>
            <div *ngIf="index == 'f3'">
              <embed src="../assets/files/Form3.pdf" type="application/pdf" width="100%" height="1200" />
            </div>
            <div *ngIf="index == 'f4'">
              <embed src="../assets/files/Form4.pdf" type="application/pdf" width="100%" height="1200" />
            </div>
            <div *ngIf="index == 'f5'">
              <embed src="../assets/files/Form5.pdf" type="application/pdf" width="100%" height="1200" />
            </div>
            <div *ngIf="index == 'f6'">
              <embed src="../assets/files/Form6.pdf" type="application/pdf" width="100%" height="1200" />
            </div>
            <div *ngIf="index == 'f7'">
              <embed src="../assets/files/Form7.pdf" type="application/pdf" width="100%" height="1200" />
            </div>
            <div *ngIf="index == 'f8'">
              <embed src="../assets/files/Form8.pdf" type="application/pdf" width="100%" height="1200" />
            </div>
          </div>
          <br>
          <div class="container row">
            <div class="col">
              <form [formGroup]="newPostForm" (ngSubmit)="addNewPost(newPostForm.value)">
                <label>Subir Formulario :</label>
                <div class="custom-file">
                  <input type="file" class="custom-file-input" id="customFileLang" lang="es" formControlName="urlPDF2" (change)="handleImage($event)" required>
                  <label id="archivo" class="custom-file-label" for="customFileLang">Seleccionar Archivo</label>
                </div>
                <br>
              </form>
            </div>
            <div class="col">
              <form (ngSubmit)="actualizarPaciente( formPaciente )" #formPaciente="ngForm"  >
                <div class="form-group">
                  <label>Diagnostico</label>
                  <input type="text"
                      class="form-control"
                      [(ngModel)]="paciente.diagnostico"
                      name="diagnostico"
                      placeholder="Diagnostico"
                      list="codigos"
                      required>
                  <datalist id="codigos">
                    <option *ngFor="let cie of cies" value="{{ cie.codigo }}">
                  </datalist>
                </div>
              </form>
            </div>
            <br>
            <button (click)="guardarTodo(formPaciente , newPostForm.value)" class="btn btn-primary container">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


