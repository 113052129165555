import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { PrimeraService } from '../../services/primera.service';
import { PrimeraModel } from '../../models/primera.model';
import { CieModel } from '../../models/cie.model';
import { CieService } from '../../services/cie.service';
import { SubsecuenteModel } from '../../models/subsecuente.model';
import { FormularioModel } from '../../models/formulario.model';
import { SubsecuenteService } from '../../services/subsecuente.service';
import { NgForm, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { FormulariosService } from '../../services/formularios.service';
import { Router } from '@angular/router';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import Swal from 'sweetalert2';
import * as moment from 'moment';


@Component({
  selector: 'app-subsecuente',
  templateUrl: './subsecuente.component.html',
  styleUrls: ['./subsecuente.component.css']
})
export class SubsecuenteComponent implements OnInit {

  pacientes: PrimeraModel[];
  paciente: PrimeraModel= new PrimeraModel();
  subsecuente: SubsecuenteModel = new SubsecuenteModel();
  subsecuentes: SubsecuenteModel[];
  buscarNom = false;
  buscarEvo = false;
  mostrarPri=false;
  mostrarFrm=false;
  
  cedulaEvolucion: string;
  nombreEvolucion: string;
  apellidoEvolucion: string;
  identificador: string;

  opcionSeleccionado: string;
  urlPDF: string;
  url: any;

  fechaFormato: string;

  nombres: string;
  apellidos: string;
  cedula: string;
  index: string ="f2";
  opcionSeleccionado2: string;
  private pdf: any;
  pdfFile= false;
  cies: CieModel[];

  constructor(private primeraService: PrimeraService,
              private subsecuenteService: SubsecuenteService,
              private route: ActivatedRoute,
              private router: Router,
              private cieService: CieService,
              private readonly formulariosService: FormulariosService ) { }

  buscarLetra='';

  ngOnInit(){

    this.primeraService.getHeroes().subscribe( resp => {
      this.pacientes = resp;
    });

    this.subsecuenteService.getHeroes().subscribe( resp => {
      this.subsecuentes = resp;
    });

    const id = this.route.snapshot.paramMap.get('id');

    if ( id !== 'nuevo' ) {
      this.subsecuenteService.getHeroe( id ).subscribe( (resp: SubsecuenteModel) => {
        this.subsecuente = resp;
        this.subsecuente.id = id;
      });
    }

    this.cieService.getCies().subscribe( resp => {
      this.cies = resp;
    });

  }

  mostrarRegistros(texto: string){
    this.buscarNom=true;
    if(texto==""){
      this.buscarNom=false;
    }
  }

  guardar( form: NgForm ) {

    this.subsecuente.fecha=this.fechaFormato;
    this.subsecuente.identificador=this.nombreEvolucion+this.apellidoEvolucion+this.cedulaEvolucion;

    if ( form.invalid ) {
      console.log('Formulario no válido');
      return;
    }

    Swal.fire({
      title: 'Espere',
      text: 'Guardando información',
      icon: 'info',
      allowOutsideClick: false
    });

    Swal.showLoading();

    let peticion: Observable<any>;

    if ( this.subsecuente.id ) {
      peticion = this.subsecuenteService.actualizarHeroe( this.subsecuente );
    } else {
      peticion = this.subsecuenteService.crearHeroe( this.subsecuente );
    }

    peticion.subscribe( resp => {

      this.subsecuentes = resp;

      Swal.fire({
        title: 'Evolución',
        text: 'Se actualizó correctamente',
        icon: 'success'
      }).then( resp => {
        document.location.reload();
      });

    });

  }

  buscarEvolucion(id: string){

    this.buscarEvo=true;
    this.buscarNom=false;
    this.mostrarPri=false;

    this.primeraService.getHeroe( id ).subscribe( (resp: PrimeraModel) => {
      this.paciente = resp;
      this.cedulaEvolucion = this.paciente.cedula;
      this.nombreEvolucion = this.paciente.nombres;
      this.apellidoEvolucion = this.paciente.apellidos;
      this.identificador=this.nombreEvolucion+this.apellidoEvolucion+this.cedulaEvolucion;
    });
    
    this.opcionSeleccionado="1";
    this.mostrarFrm=false;

  }

  change(){

    this.url=this.formulariosService.getPDF(this.nombreEvolucion, this.apellidoEvolucion, this.opcionSeleccionado);
    
    this.url.getDownloadURL().subscribe(url => {
      this.urlPDF = url;
      this.mostrarFrm=true;
    }, err => {

      this.mostrarFrm=false;
        console.log(err);
        Swal.fire({
          title: 'El paciente no tiene registrado este PDF',
          text: 'Registrelo primero',
          icon: 'error',
          allowOutsideClick: false
        });
        return;

    });

  }

  borrarEvolucion( evolucion: SubsecuenteModel, i: number ) {

    Swal.fire({
      title: '¿Está seguro?',
      text: `Está seguro que desea borrar la evolucion del ${ evolucion.fecha }`,
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true
    }).then( resp => {

      if ( resp.value ) {
        this.subsecuentes.splice(i, 1);
        this.subsecuenteService.borrarHeroe( evolucion.id ).subscribe();
      }

      document.location.reload();

    });

  }

  @ViewChild('content') content: ElementRef;
  public downloadPDF(){
    
    window["html2canvas"] = html2canvas;
    var doc = new jsPDF("p","pt","a4");

    doc.html(document.getElementById('imprimir') , {
       callback: function (doc) {
         doc.save();
       }
    });

  }

  editarEvolucion(evolucion: SubsecuenteModel, i: number){
    Swal.fire({
      title: `Evolución de ${ evolucion.fecha }`,
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonText: "Borrar",
      confirmButtonText: "Editar"
    }).then( resp => {

      if ( resp.isConfirmed ) {
        this.router.navigate([`/actualizar-evolucion/${ evolucion.id }`]);
      }
      if ( resp.isDismissed ) {
        this.borrarEvolucion(evolucion, i);
      }

    });
  }

  obtenerFecha(e)
  {
    var fecha = moment(e.value);
    this.fechaFormato=fecha.format("DD/MM/YYYY");
  }

  mostrarPrimera(id: string, nombrePaciente: string, apellidoPaciente: string, cedulaPaciente: string){

    this.mostrarPri=true;
    this.buscarEvo=false;
    this.buscarNom=false;
    this.nombres=nombrePaciente;
    this.apellidos=apellidoPaciente;
    this.cedula=cedulaPaciente;

    this.primeraService.getHeroe( id ).subscribe( (resp: PrimeraModel) => {
      this.paciente = resp;
      this.paciente.id = id;
    });

  }

  handleImage(event: any): void {
    this.pdfFile=true;
    document.getElementById("archivo").innerHTML="PDF subido";
    this.pdf = event.target.files[0];
  }

  public newPostForm = new FormGroup({
    urlPDF2:new FormControl('', Validators.required),
  });

  addNewPost(data: FormularioModel) {

    if ( this.pdfFile==false ) {

      console.log('Formulario no válido pdf');
      Swal.fire({
        title: 'Formulario no válido',
        text: 'Suba el archivo PDF',
        icon: 'error',
        confirmButtonText: "Aceptar",
        allowOutsideClick: false
      });
      return;

    }

    console.log('New post pdf', data);
    data.nombre=this.nombres;
    data.apellido=this.apellidos;
    data.cedula=this.cedula;
    this.formulariosService.uploadPDF(data, this.pdf, data.nombre, data.apellido, this.opcionSeleccionado2);
    
  }

  changePrimera(){
    this.index = this.opcionSeleccionado2;
  }

  actualizarPaciente( form: NgForm ) {

    Swal.fire({
      title: 'Espere',
      text: 'Guardando información',
      icon: 'info',
      allowOutsideClick: false
    });

    Swal.showLoading();
    
    let peticion: Observable<any>;

    if ( this.paciente.id ) {
      peticion = this.primeraService.actualizarHeroe( this.paciente );
    } 

    peticion.subscribe( resp => {

      Swal.fire({
        title: this.paciente.nombres,
        text: 'Se actualizó correctamente',
        icon: 'success'
      }).then( resp => {
        document.location.reload();
      });

    });

  }

  guardarTodo(form: NgForm, data: FormularioModel){
    this.addNewPost(data);
    this.actualizarPaciente(form);
  } 

}