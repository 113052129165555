import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { CieModel } from '../../models/cie.model';
import { CieService } from '../../services/cie.service';
import { PrimeraService } from '../../services/primera.service';
import { PrimeraModel } from '../../models/primera.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pacientes',
  templateUrl: './pacientes.component.html',
  styleUrls: ['./pacientes.component.css']
})
export class PacientesComponent implements OnInit {

  cie: CieModel = new CieModel();
  cies: CieModel[];

  pacientes: PrimeraModel[];

  constructor(private cieService: CieService,
              private primeraService: PrimeraService,
              private route: ActivatedRoute, ) { }

  buscarLetra='';

  ngOnInit(): void {

    this.primeraService.getHeroes().subscribe( resp => {
      this.pacientes = resp;
    });

  }

  guardar( form: NgForm ) {

    if ( form.invalid ) {
      console.log('Formulario no válido');
      return;
    }

    Swal.fire({
      title: 'Espere',
      text: 'Guardando información',
      icon: 'info',
      allowOutsideClick: false
    });

    Swal.showLoading();

    let peticion: Observable<any>;

    if ( this.cie.id ) {
      peticion = this.cieService.actualizarCie( this.cie );
    } else {
      peticion = this.cieService.crearCie( this.cie );
    }

    peticion.subscribe( resp => {

      Swal.fire({
        title: this.cie.codigo,
        text: 'Se actualizó correctamente',
        icon: 'success'
      });

    });

  }

  borrarPaciente( paciente: PrimeraModel, i: number ) {

    Swal.fire({
      title: '¿Está seguro?',
      text: `Está seguro que desea borrar a ${ paciente.nombres }`,
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true
    }).then( resp => {

      if ( resp.value ) {
        this.pacientes.splice(i, 1);
        this.primeraService.borrarHeroe( paciente.id ).subscribe();
      }

    });
    
  }

  exportTableToExcel(filename = 'Listado de Pacientes'){
    
    var downloadLink;
    var dataType = 'application/vnd.ms-excel';
    var tableSelect = document.getElementById("tablaPacientes");
    var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');
    
    // Specify file name
    filename = filename?filename+'.xls':'excel_data.xls';
    
    // Create download link element
    downloadLink = document.createElement("a");
    
    document.body.appendChild(downloadLink);
    
    if(navigator.msSaveOrOpenBlob){
        var blob = new Blob(['ufeff', tableHTML], {
            type: dataType
        });
        navigator.msSaveOrOpenBlob( blob, filename);
    }else{
        // Create a link to the file
        downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
    
        // Setting the file name
        downloadLink.download = filename;
        
        //triggering the function
        downloadLink.click();
    }

  }

}
