import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { PrimeraService } from '../../services/primera.service';
import { PrimeraModel } from '../../models/primera.model';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import * as moment from 'moment';

@Component({
  selector: 'app-reporte-diario',
  templateUrl: './reporte-diario.component.html',
  styleUrls: ['./reporte-diario.component.css']
})
export class ReporteDiarioComponent implements OnInit {

  pacientes: PrimeraModel[];
  cargando = false;
  interval;
  fechas: string;

  constructor( private primeraService: PrimeraService ) { }
  
  ngOnInit() {

    this.primeraService.getHeroes().subscribe( resp => {
      this.pacientes = resp;
    });

    moment.locale('es');
    var nowDate = moment(new Date());
    var elFecha = document.querySelector("#fecha");
    elFecha.textContent = nowDate.format('dddd DD [de] MMMM [de] YYYY');
    this.fechas = elFecha.textContent;  
        
  }

  @ViewChild('content') content: ElementRef;
  public downloadPDF(){
    
    window["html2canvas"] = html2canvas;
    var doc = new jsPDF("p","pt","a4");

    var options = {
      callback: function (doc) {
        doc.save();
      } 
    };

    doc.html(document.getElementById('imprimir') , options);

  }

}
