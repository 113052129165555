import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { FileModel } from '../models/file.model';
import { Observable } from 'rxjs';
import { ExamenModel } from '../models/examen.model';
import { HttpClient } from '@angular/common/http';
import { map, delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LaboratorioService {

  private url = 'https://clinica-581fe.firebaseio.com/';
  private postsCollection: AngularFirestoreCollection<ExamenModel>;
  private filePath: any;
  private nombre: any;
  private downloadURL: Observable<string>;

  constructor(private afs: AngularFirestore,
              private storage: AngularFireStorage,
              private http: HttpClient) { }

  public uploadPDF(pdf: FileModel, nombre: string, apellido: string) {
    this.nombre = `${pdf.name}`;
    this.filePath = `Examenes/${nombre} ${apellido}/${pdf.name}`;
    const fileRef = this.storage.ref(this.filePath);
    const task = this.storage.upload(this.filePath, pdf);
  }

  public getPDF(nombrePdf: any, nombre: string, apellido: string): any {
    this.filePath = `Examenes/${nombre} ${apellido}/${nombrePdf}`;
    const fileRef = this.storage.ref(this.filePath);
    return fileRef;
  }

  public borrarPDF(nombrePdf: any, nombre: string, apellido: string): any {
    this.filePath = `Examenes/${nombre} ${apellido}/${nombrePdf}`;
    this.storage.ref(this.filePath).delete();
  }

  crearExamen( examen: ExamenModel ) {

    return this.http.post(`${ this.url }/examenes.json`, examen).pipe(map( (resp: any) => {
      examen.id = resp.name;
      return examen;
    }));

  }

  actualizarExamen( examen: ExamenModel ) {

    const examenTemp = {
      ...examen
    };

    delete examenTemp.id;

    return this.http.put(`${ this.url }/examenes/${ examen.id }.json`, examenTemp);

  }

  borrarExamen( id: string ) {

    return this.http.delete(`${ this.url }/examenes/${ id }.json`);

  }


  getExamen( id: string ) {

    return this.http.get(`${ this.url }/examenes/${ id }.json`);

  }


  getExamenes() {
    return this.http.get(`${ this.url }/examenes.json`).pipe(map( this.crearArreglo ), delay(0));
  }

  private crearArreglo( examenesObj: object ) {

    const examenes: ExamenModel[] = [];

    Object.keys( examenesObj ).forEach( key => {
      const examen: ExamenModel = examenesObj[key];
      examen.id = key;
      examenes.push( examen );
    });

    return examenes;

  }

}
