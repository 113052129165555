<div id="login" class="container">
    <div id="contenedor" class="row mt-5">
      <div class="col-12 p-5">
        <div class="row">
          <div class="col-md-7 mx-auto mt-5">
            <div class="card">
              <div class="card-body">
                <h1 class="text-center mb-4">SERVICIOS EN LINEA</h1>
                <form>
                  <div class="form-group">
                    <label for="email">Cedula/RUC</label>
                    <input [(ngModel)]="input.cedula" name="cedula" type="text" class="form-control" name="buscarCedula"/>
                  </div>
                  <div class="form-group">
                    <label for="password">Contraseña</label>
                    <input [(ngModel)]="input.fecha_registro" name="password" type="password" class="form-control"/>
                  </div>
                  <div class="row justify-content-center">
                    <div class="m-3">
                        <input class="mr-2" type="radio" name="lab" id="paciente" value="paciente" (change)="changeRadio1($event)" required checked="checked">
                        <i class="fa fa-user fa-2x mr-1" aria-hidden="true"></i>
                        <label  for="paciente">Paciente</label>
                    </div>
                    <div class="m-3">
                      <input class="mr-2" type="radio" name="lab" id="empresa" value="empresa" (change)="changeRadio4($event)">
                      <i class="fa fa-hospital-o fa-2x mr-1" aria-hidden="true"></i>
                      <label class="ml-1" for="empresa">Empresa/Laboratorio Clínico</label>
                    </div>
                    <div class="m-3">
                      <input class="mr-2" type="radio" name="lab" id="rayosx" value="rayosx" (change)="changeRadio3($event)">
                      <i class="fa fa-upload fa-2x mr-1" aria-hidden="true"></i>
                      <!-- <label class="ml-1" for="rayosx">Radiografías</label> -->
                      <label class="ml-1" for="rayosx">Subir Examen de Rayos-X</label>
                    </div>
                    <div class="m-3">
                      <input class="mr-2" type="radio" name="lab" id="laboratorio" value="laboratorio" (change)="changeRadio2($event)">
                      <i class="fa fa-upload fa-2x mr-1" aria-hidden="true"></i>
                      <label class="ml-1" for="laboratorio">Subir Examen de Laboratorio</label>
                    </div>                    
                    <div class="m-3">
                      <input class="mr-2" type="radio" name="lab" id="audiometria" value="audiometria" (change)="changeRadio5($event)">
                      <i class="fa fa-upload fa-2x mr-1" aria-hidden="true"></i>
                      <label class="ml-1" for="audiometria">Subir Examen de Audiometría</label>
                    </div>
                    <div class="m-3">
                      <input class="mr-2" type="radio" name="lab" id="optometria" value="optometria" (change)="changeRadio6($event)">
                      <i class="fa fa-upload fa-2x mr-1" aria-hidden="true"></i>
                      <label class="ml-1" for="optometria">Subir Examen de Optometría</label>
                    </div>
                  </div>
                  <button class="btn btn-primary btn-lg btn-block"  (click)="auth()">Entrar</button>
                </form>
              </div>
            </div>
          </div>
        </div>      
      </div>
    </div>
  </div>