import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AngularFirestore,  AngularFirestoreDocument } from '@angular/fire/firestore';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { User } from '../../models/user.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  registerForm = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
    displayName: new FormControl(''),
    cedula: new FormControl(''),
    especialidad: new FormControl(''),
  });

  constructor(private authSvc: AuthService, private router: Router,private afs: AngularFirestore) { }

  ngOnInit(): void {
  }

  async onRegister() {

    const { email, password, displayName, cedula, especialidad } = this.registerForm.value;

    try {
      const user = await this.authSvc.register(email, password);

      const userRef: AngularFirestoreDocument<User> = this.afs.doc(
        `users/${user.uid}`
      );
        
      const data: User = {
        uid: user.uid,
        email: user.email,
        emailVerified: user.emailVerified,
        displayName: displayName,
        photoURL: user.photoURL,
        role: 'ADMIN',
        cedula: cedula,
        especialidad: especialidad,
      };
  
      userRef.set(data, { merge: true });

      if (user) {
        this.checkUserIsVerified(user);
      }

    } catch (error) {
      console.log(error);
    }

  }

  private checkUserIsVerified(user: User) {

    if (user && user.emailVerified) {
      this.router.navigate(['/primera/nuevo']);
    } else if (user) {

      Swal.fire({
        title: 'Hemos enviado un correo electrónico de confirmación a '+user.email,
        text: 'Verifique su correo electrónico y haga clic en el enlace para verificar su dirección de correo electrónico.',
        icon: 'success'
      }).then((result) => {
        this.router.navigate(['/login']);
      });

    } else {

      Swal.fire({
        title: user.email,
        text: 'Correo ya registrado',
        icon: 'error'
      })

    }
    
  }

  // ngOnDestroy() {
  //   this.authSvc.logout();
  // }

}
