import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { PrimeraService } from '../../services/primera.service';
import { PrimeraModel } from '../../models/primera.model';
import { FormulariosService } from '../../services/formularios.service';
import { FormularioModel } from '../../models/formulario.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-actualizar-paciente',
  templateUrl: './actualizar-paciente.component.html',
  styleUrls: ['./actualizar-paciente.component.css']
})
export class ActualizarPacienteComponent implements OnInit {

  paciente: PrimeraModel = new PrimeraModel();
  opcionSeleccionado: string;
  index: string;
  private pdf: any;

  constructor(private primeraService: PrimeraService,
              private route: ActivatedRoute,
              private readonly formulariosService: FormulariosService,
              private router: Router ) { }

  ngOnInit(): void {

    const id = this.route.snapshot.paramMap.get('id');

    if ( id !== 'nuevo' ) {
      this.primeraService.getHeroe( id ).subscribe( (resp: PrimeraModel) => {
        this.paciente = resp;
        this.paciente.id = id;
      });
    }

  }

  actualizarPaciente( form: NgForm ) {
    
    if ( form.invalid ) {
      console.log('Formulario no válido');
      Swal.fire({
        title: 'Formulario no válido',
        text: 'Ingrese los datos',
        icon: 'error',
        allowOutsideClick: false
      });
      return;
    }

    Swal.fire({
      title: 'Espere',
      text: 'Guardando información',
      icon: 'info',
      allowOutsideClick: false
    });

    Swal.showLoading();
    
    let peticion: Observable<any>;

    if ( this.paciente.id ) {
      peticion = this.primeraService.actualizarHeroe( this.paciente );
    } else {
      peticion = this.primeraService.crearHeroe( this.paciente );
    }

    peticion.subscribe( resp => {

      Swal.fire({
        title: this.paciente.nombres,
        text: 'Se actualizó correctamente',
        icon: 'success'
      });

    });

    this.router.navigate(['/pacientes/nuevo']);

  }

  change(){
    this.index = this.opcionSeleccionado;
  }

  public newPostForm = new FormGroup({
    urlPDF:new FormControl('', Validators.required),
  });

  addNewPost(data: FormularioModel) {

    console.log('New post pdf', data);
    data.nombre=((document.getElementById("nombres") as HTMLInputElement).value);
    data.apellido=((document.getElementById("apellidos") as HTMLInputElement).value);
    data.cedula=((document.getElementById("cedula") as HTMLInputElement).value);
    this.formulariosService.uploadPDF(data, this.pdf, data.nombre, data.apellido, this.opcionSeleccionado);

    Swal.fire({
      title: 'PDF Subido correctamente',
      text: '',
      icon: 'success'
    }).then((result) => {
      this.router.navigate(['/subsecuente/nuevo']);
    });
    
  }

  handleImage(event: any): void {
    document.getElementById("archivo2").innerHTML="PDF subido";
    this.pdf = event.target.files[0];
  }

}
