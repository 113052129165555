<div id="actualizar-evolucion" class="container">
    <div class="row">
        <div class="col-12 p-5">
            <br><br>
            <div id="contenedor" class="bg-secondary p-5">
                <form (ngSubmit)="guardar( f )" #f="ngForm">
                  <h1>EVOLUCIÓN</h1> 
                  <hr>
                  <br>
                  <div class="left">
                    <div class="form-group">
                        <label> Fecha</label>
                        <input type="date" class="form-control" placeholder="Fecha" [(ngModel)]="subsecuente.fecha" name="fecha" required>
                    </div>
                  </div>
                  <div class="right">
                    <div class="form-group">
                      <label>Hora</label>
                      <input type="time" class="form-control" [(ngModel)]="subsecuente.hora" name="hora" placeholder="Hora">
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Notas de Evolución</label>
                    <input type="text" class="form-control" [(ngModel)]="subsecuente.notas_evolucion" name="notas_evolucion" placeholder="Notas de Evolución">
                  </div>
                  <div class="form-group">
                    <label>Farmacoterapia e Indicaciones</label>
                    <input type="text" class="form-control" [(ngModel)]="subsecuente.farmacoterapia" name="farmacoterapia" placeholder="Farmacoterapia e Indicaciones">
                  </div>
                  <div class="form-group">
                    <label>Farmacos</label>
                    <input type="text" class="form-control" [(ngModel)]="subsecuente.farmacos" name="farmacos" placeholder="Farmacos">
                  </div>
                  <br><br>
                  <div class="form-group">
                    <button type="submit" class="btn btn-primary container">Guardar</button>
                  </div>    
                </form>
            </div>
        </div>
    </div>
</div>
        