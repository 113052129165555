import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'edad'
})
export class EdadPipe implements PipeTransform {

  showAge: number;

  transform(value: any): any {

      if(value){
        const convertAge = new Date(value);
        const timeDiff = Math.abs(Date.now() - convertAge.getTime());
        this.showAge = Math.floor((timeDiff / (1000 * 3600 * 24))/365);
      }

    return this.showAge.toString() + " Años";
  }

}
