import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { User } from '../../models/user.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css']
})
export class PerfilComponent implements OnInit {

  public user$: Observable<User> = this.authSvc.afAuth.user;
  usuario: User;

  constructor(public authSvc: AuthService, private router: Router) { }

  ngOnInit(): void {

    this.user$.subscribe(user => {
    
      this.authSvc.getUser( user.uid ).subscribe( (resp: User) => {
        this.usuario = resp;
        this.usuario.uid =user.uid;
      });
    
    });

  }

  registerLaboratorio() {
    this.router.navigate(['/register-clinicaLab']);
  }

  registerRayosX() {
    this.router.navigate(['/register-radiografiaLab']);
  }

  registerAudiometria() {
    this.router.navigate(['/register-audiometriaLab']);
  }

  registerOptometria() {
    this.router.navigate(['/register-optometriaLab']);
  }

  registerEmpresa() {
    this.router.navigate(['/register-empresaLab']);
  }

  async registerDoctor() {

    // try {
    //   await this.authSvc.logout();
    //   this.router.navigate(['/register']);
    // } catch (error) {
    //   console.log(error);
    // }
    
    this.router.navigate(['/register']);

  }

}
