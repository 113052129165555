<div id="pacientes" class="mx-5">
    <div class=" mt-5">
      <div class="pt-5 my-5">
        <div id="contenedor" class="animated fadeIn faster p-4">
            <h1 class="text-center my-3">DOCUMENTOS DE PACIENTES</h1>
            <h1 class="text-center my-3">{{nombreEmpresa}}</h1>
          <!-- <form class="container">
            <input class="form-control mr-sm-2" name="buscarLetra" type="text" placeholder="Buscar paciente"  #buscarTexto [(ngModel)]="buscarLetra">
          </form> -->
            <!-- <label for="start">Seleccionar fecha:</label>
            <br>
            <input type="date" id="start" name="trip-start">
            <br> -->
            <br>
            <div class="" *ngIf="mostrarFrm===true">
                <div class="text-center">
                    <button class="btn btn-info p-1 m-1" (click)="mostrarFrm=false">Cerrar Documento</button>
                </div>
                <br>
                <embed [src]="urlPDF | safe" type="application/pdf" width="100%" height="1200" />
            </div>
            <div *ngIf="mostrarFrm===false">
                <button class="btn btn-info p-1 m-1" (click)="mostrarEx=true; mostrarAu=false; mostrarOp=false; mostrarRx=false">
                    <a>Laboratorio</a>
                </button>
                <button class="btn btn-info p-1 m-1" (click)="mostrarAu=true; mostrarEx=false; mostrarOp=false; mostrarRx=false">
                    <a>Audiometría</a>
                </button>
                <button class="btn btn-info p-1 m-1" (click)="mostrarOp=true; mostrarEx=false; mostrarAu=false; mostrarRx=false">
                    <a>Optometría</a>
                </button>
                <button class="btn btn-info p-1 m-1" (click)="mostrarRx=true; mostrarEx=false; mostrarAu=false; mostrarOp=false">
                    <a>Radiografias</a>
                </button>
                <button class="btn btn-info p-1 m-1" (click)="mostrarRx=true; mostrarEx=true; mostrarAu=true; mostrarOp=true">
                    <a>Ver todo</a>
                </button>                
                <table id='tablaPacientes' class="table mt-3 animated fadeIn faster bg-secondary table-hover">
                    <thead class="thead-dark">
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Cedula del Paciente</th>
                        <th *ngIf="mostrarEx===true" scope="col">Examen de Laboratorio 1</th>
                        <th *ngIf="mostrarEx===true" scope="col">Examen de Laboratorio 2</th>
                        <th *ngIf="mostrarAu===true"  scope="col">Examen de Audiometría</th>
                        <th *ngIf="mostrarOp===true" scope="col">Examen de Optometría</th>
                        <th *ngIf="mostrarRx===true" scope="col">Informe de Radiografía 1</th>
                        <th *ngIf="mostrarRx===true" scope="col">Radiografía 1</th>
                        <th *ngIf="mostrarRx===true" scope="col">Informe de Radiografía 2</th>
                        <th *ngIf="mostrarRx===true" scope="col">Radiografía 2</th>
                        <th *ngIf="mostrarRx===true" scope="col">Informe de Radiografía 3</th>
                        <th *ngIf="mostrarRx===true" scope="col">Radiografía 3</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let paciente of pacientes | filterEmpresa:[nombreEmpresa] ; let i = index">
                        <td>{{ i+1 }}</td>
                        <td>{{ paciente.cedula }}</td>
                        <td *ngIf="mostrarEx===true">
                            <div class="row">
                                <button class="btn btn-info p-1 m-1" (click)="downloadPdf(paciente.cedula, 'Examen-Laboratorio-1')">
                                <i class="fa fa-download" aria-hidden="true"><a> Descargar</a></i>
                                </button>
                                <button class="btn btn-info p-1 m-1" (click)="viewPdf(paciente.cedula, 'Examen-Laboratorio-1')">
                                    <i class="fa fa-eye" aria-hidden="true"><a> Ver</a></i>
                                </button>
                            </div>
                        </td>
                        <td *ngIf="mostrarEx===true">
                            <div class="row">
                                <button class="btn btn-info p-1 m-1" (click)="downloadPdf(paciente.cedula, 'Examen-Laboratorio-2')">
                                <i class="fa fa-download" aria-hidden="true"><a> Descargar</a></i>
                                </button>
                                <button class="btn btn-info p-1 m-1" (click)="viewPdf(paciente.cedula, 'Examen-Laboratorio-2')">
                                    <i class="fa fa-eye" aria-hidden="true"><a> Ver</a></i>
                                </button>
                            </div>
                        </td>
                        <td *ngIf="mostrarAu===true">
                            <div class="row">
                                <button class="btn btn-info p-1 m-1" (click)="downloadPdf(paciente.cedula, 'Examen-Audiometria')">
                                <i class="fa fa-download" aria-hidden="true"><a> Descargar</a></i>
                                </button>
                                <button class="btn btn-info p-1 m-1" (click)="viewPdf(paciente.cedula, 'Examen-Audiometria')">
                                    <i class="fa fa-eye" aria-hidden="true"><a> Ver</a></i>
                                </button>
                            </div>
                        </td>
                        <td *ngIf="mostrarOp===true">
                            <div class="row">
                                <button class="btn btn-info p-1 m-1" (click)="downloadPdf(paciente.cedula, 'Examen-Optometria')">
                                <i class="fa fa-download" aria-hidden="true"><a> Descargar</a></i>
                                </button>
                                <button class="btn btn-info p-1 m-1" (click)="viewPdf(paciente.cedula, 'Examen-Optometria')">
                                    <i class="fa fa-eye" aria-hidden="true"><a> Ver</a></i>
                                </button>
                            </div>
                        </td>                                         
                        <td *ngIf="mostrarRx===true">
                            <div class="row">
                                <button class="btn btn-info p-1 m-1" (click)="downloadPdf(paciente.cedula, 'Informe-RayosX-1')">
                                <i class="fa fa-download" aria-hidden="true"><a> Descargar</a></i>
                                </button>
                                <button class="btn btn-info p-1 m-1" (click)="viewPdf(paciente.cedula, 'Informe-RayosX-1')">
                                    <i class="fa fa-eye" aria-hidden="true"><a> Ver</a></i>
                                </button>
                            </div>
                        </td>
                        <td *ngIf="mostrarRx===true">
                            <div class="row">
                                <button class="btn btn-info p-1 m-1" (click)="downloadPdf(paciente.cedula, 'Imagen-RayosX-1')">
                                <i class="fa fa-download" aria-hidden="true"><a> Descargar</a></i>
                                </button>
                                <button class="btn btn-info p-1 m-1" (click)="viewPdf(paciente.cedula, 'Imagen-RayosX-1')">
                                    <i class="fa fa-eye" aria-hidden="true"><a> Ver</a></i>
                                </button>
                            </div>
                        </td>                    
                        <td *ngIf="mostrarRx===true">
                            <div class="row">
                                <button class="btn btn-info p-1 m-1" (click)="downloadPdf(paciente.cedula, 'Informe-RayosX-2')">
                                <i class="fa fa-download" aria-hidden="true"><a> Descargar</a></i>
                                </button>
                                <button class="btn btn-info p-1 m-1" (click)="viewPdf(paciente.cedula, 'Informe-RayosX-2')">
                                    <i class="fa fa-eye" aria-hidden="true"><a> Ver</a></i>
                                </button>
                            </div>
                        </td>
                        <td *ngIf="mostrarRx===true">
                            <div class="row">
                                <button class="btn btn-info p-1 m-1" (click)="downloadPdf(paciente.cedula, 'Imagen-RayosX-2')">
                                <i class="fa fa-download" aria-hidden="true"><a> Descargar</a></i>
                                </button>
                                <button class="btn btn-info p-1 m-1" (click)="viewPdf(paciente.cedula, 'Imagen-RayosX-2')">
                                    <i class="fa fa-eye" aria-hidden="true"><a> Ver</a></i>
                                </button>
                            </div>
                        </td>                    
                        <td *ngIf="mostrarRx===true">
                            <div class="row">
                                <button class="btn btn-info p-1 m-1" (click)="downloadPdf(paciente.cedula, 'Informe-RayosX-3')">
                                <i class="fa fa-download" aria-hidden="true"><a> Descargar</a></i>
                                </button>
                                <button class="btn btn-info p-1 m-1" (click)="viewPdf(paciente.cedula, 'Informe-RayosX-3')">
                                    <i class="fa fa-eye" aria-hidden="true"><a> Ver</a></i>
                                </button>
                            </div>
                        </td>
                        <td *ngIf="mostrarRx===true">
                            <div class="row">
                                <button class="btn btn-info p-1 m-1" (click)="downloadPdf(paciente.cedula, 'Imagen-RayosX-3')">
                                <i class="fa fa-download" aria-hidden="true"><a> Descargar</a></i>
                                </button>
                                <button class="btn btn-info p-1 m-1" (click)="viewPdf(paciente.cedula, 'Imagen-RayosX-3')">
                                    <i class="fa fa-eye" aria-hidden="true"><a> Ver</a></i>
                                </button>
                            </div>
                        </td>                    
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>      
      </div>
    </div>
  </div>
