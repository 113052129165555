import { Injectable, Output, EventEmitter  } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PrimeraModel } from '../models/primera.model';
import { PacienteModel } from '../models/paciente.model';
import { ClinicaLabModel } from '../models/clinicaLab.model';
import { RadiografiaLabModel } from '../models/radiografiaLab.model';
import { EmpresaLabModel } from '../models/empresaLab.model';
import { AudiometriaLabModel } from '../models/audiometriaLab.model';
import { OptometriaLabModel } from '../models/optometriaLab.model';
import { map, delay } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class PrimeraService {

  private url = 'https://clinica-581fe.firebaseio.com/';

  constructor( private http: HttpClient ) { }

  crearHeroe( heroe: PrimeraModel ) {

    return this.http.post(`${ this.url }/pacientes.json`, heroe).pipe(map( (resp: any) => {
      heroe.id = resp.name;
      return heroe;
    }));

  }

  actualizarHeroe( heroe: PrimeraModel ) {

    const heroeTemp = {
      ...heroe
    };

    delete heroeTemp.id;

    return this.http.put(`${ this.url }/pacientes/${ heroe.id }.json`, heroeTemp);

  }

  borrarHeroe( id: string ) {

    return this.http.delete(`${ this.url }/pacientes/${ id }.json`);

  }


  getHeroe( id: string ) {

    return this.http.get(`${ this.url }/pacientes/${ id }.json`);

  }


  getHeroes() {
    return this.http.get(`${ this.url }/pacientes.json`).pipe(map( this.crearArreglo ), delay(0));
  }

  private crearArreglo( heroesObj: object ) {

    const heroes: PrimeraModel[] = [];

    Object.keys( heroesObj ).forEach( key => {
      const heroe: PrimeraModel = heroesObj[key];
      heroe.id = key;
      heroes.push( heroe );
    });

    return heroes;

  }

  private crearArregloPacientes( pacienteObj: object ) {

    const pacientes: PacienteModel[] = [];

    Object.keys( pacienteObj ).forEach( key => {
      const paciente: PacienteModel = pacienteObj[key];
      paciente.id = key;
      pacientes.push( paciente );
    });

    return pacientes;

  }

  private crearArregloClinicaLab( clinicaObj: object ) {

    const clinicaLabs: ClinicaLabModel[] = [];

    Object.keys( clinicaObj ).forEach( key => {
      const clinicaLab: ClinicaLabModel = clinicaObj[key];
      clinicaLab.id = key;
      clinicaLabs.push( clinicaLab );
    });

    return clinicaLabs;

  }

  private crearArregloRadiografiaLab( radiografiaObj: object ) {

    const radiografiaLabs: RadiografiaLabModel[] = [];

    Object.keys( radiografiaObj ).forEach( key => {
      const radiografiaLab: RadiografiaLabModel = radiografiaObj[key];
      radiografiaLab.id = key;
      radiografiaLabs.push( radiografiaLab );
    });

    return radiografiaLabs;

  }

  private crearArregloEmpresaLab( empresaObj: object ) {

    const empresaLabs: EmpresaLabModel[] = [];

    Object.keys( empresaObj ).forEach( key => {
      const empresaLab: EmpresaLabModel = empresaObj[key];
      empresaLab.id = key;
      empresaLabs.push( empresaLab );
    });

    return empresaLabs;

  }

  private crearArregloAudiometriaLab( audiometriaObj: object ) {

    const audiometriaLabs: AudiometriaLabModel[] = [];

    Object.keys( audiometriaObj ).forEach( key => {
      const audiometriaLab: AudiometriaLabModel = audiometriaObj[key];
      audiometriaLab.id = key;
      audiometriaLabs.push( audiometriaLab );
    });

    return audiometriaLabs;

  }

  private crearArregloOptometriaLab( optometriaObj: object ) {

    const optometriaLabs: OptometriaLabModel[] = [];

    Object.keys( optometriaObj ).forEach( key => {
      const optometriaLab: OptometriaLabModel = optometriaObj[key];
      optometriaLab.id = key;
      optometriaLabs.push( optometriaLab );
    });

    return optometriaLabs;

  }

  //API para Servicios en linea

  crearPaciente( paciente: PacienteModel ) {

    return this.http.post(`${ this.url }/pacienteslab.json`, paciente).pipe(map( (resp: any) => {
      paciente.id = resp.name;
      return paciente;
    }));
  }

  getPacientes() {
    return this.http.get(`${ this.url }/pacienteslab.json`).pipe(map( this.crearArregloPacientes ));
  }

  crearClinicaLab( clinica: ClinicaLabModel ) {

    return this.http.post(`${ this.url }/clinicaLab.json`, clinica).pipe(map( (resp: any) => {
      clinica = resp;
      return clinica;
    }));
  }

  getClinicaLab() {
    return this.http.get(`${ this.url }/clinicaLab.json`).pipe(map( this.crearArregloClinicaLab ), delay(0));

  }

  crearRadiografiaLab( radiografia: RadiografiaLabModel ) {

    return this.http.post(`${ this.url }/radiografiaLab.json`, radiografia).pipe(map( (resp: any) => {
      radiografia = resp;
      return radiografia;
    }));
  }

  getRadiografiaLab() {
    return this.http.get(`${ this.url }/radiografiaLab.json`).pipe(map( this.crearArregloRadiografiaLab ), delay(0));

  }

  crearEmpresaLab( empresa: EmpresaLabModel ) {

    return this.http.post(`${ this.url }/empresaLab.json`, empresa).pipe(map( (resp: any) => {
      empresa = resp;
      return empresa;
    }));
  }

  getEmpresaLab() {
    return this.http.get(`${ this.url }/empresaLab.json`).pipe(map( this.crearArregloEmpresaLab ), delay(0));

  }

  crearAudiometriaLab( audiometria: AudiometriaLabModel ) {

    return this.http.post(`${ this.url }/audiometriaLab.json`, audiometria).pipe(map( (resp: any) => {
      audiometria = resp;
      return audiometria;
    }));
  }

  getAudiometriaLab() {
    return this.http.get(`${ this.url }/audiometriaLab.json`).pipe(map( this.crearArregloAudiometriaLab ), delay(0));

  }

  crearOptometriaLab( optometria: OptometriaLabModel ) {

    return this.http.post(`${ this.url }/optometriaLab.json`, optometria).pipe(map( (resp: any) => {
      optometria = resp;
      return optometria;
    }));
  }

  getOptometriaLab() {
    return this.http.get(`${ this.url }/optometriaLab.json`).pipe(map( this.crearArregloOptometriaLab ), delay(0));

  }




}
