<div id="login" class="container">
  <div id="contenedor" class="row mt-5">
    <div class="col-12 p-5">
      <div class="row">
        <div class="col-md-6 mx-auto mt-5">
          <div class="card">
            <div class="card-body">
              <h1 class="text-center">INICIAR SESIÓN</h1>
              <form [formGroup]="loginForm" (ngSubmit)="onLogin()">
                <div class="form-group">
                  <label for="email">Correo</label>
                  <input type="text" class="form-control" formControlName="email" />
                </div>
                <div class="form-group">
                  <label for="password">Contraseña</label>
                  <input type="password" class="form-control" formControlName="password"/>
                </div>
                <button class="btn btn-primary btn-lg btn-block">Entrar</button>
              </form>
            </div>
          </div>
        </div>
      </div>      
    </div>
  </div>
</div>

  
