<div class="container">
    <div class="mt-5 pt-5">
        <div id="contenedor" class="p-3 my-5">
            <div class="col">
                <!-- <h1 class="text-center">Radiografía</h1> -->
                <h1 class="text-center">Servicio de radiografía</h1>
                <form (ngSubmit)="guardar( f )" #f="ngForm">
                    <h2>Ingrese la cedula del paciente</h2>
                    <input type="text"
                        class="form-control"
                        placeholder="Cedula"
                        [(ngModel)]="paciente.cedula"
                        name="nombres"
                        id="nombres"
                        required >
                    <br>
                    <h2>Ingrese la Empresa/Laboratorio</h2>
                    <select class="form-control"  [(ngModel)]="paciente.empresa" name="empresa" id="empresa" required>
                        <option value="undefined" hidden selected>Selecciona una Empresa o Laboratorio</option>
                        <option *ngFor="let empresa of empresas" [ngValue]="empresa.nombre">{{empresa.nombre}}</option>
                    </select>
                    <br>
                    <br>
                    <form [formGroup]="newPostForm">
                        <h2>Subir informe de Radiografía 1</h2>
                        <div class="custom-file col-13">
                            <input type="file" class="custom-file-input" id="customFileLang1" lang="es" formControlName="PDF1" (change)="showPDF1($event)" required>
                            <label id="archivo1" class="custom-file-label" for="customFileLang1" required>Seleccionar Informe 1</label>
                        </div>
                        <br>
                        <br>
                        <h2>Subir imagen de Radiografía 1</h2>
                        <div class="custom-file col-13">
                            <input type="file" class="custom-file-input" id="customFileLang2" lang="es" formControlName="PDF2" (change)="showPDF2($event)" required>
                            <label id="archivo2" class="custom-file-label" for="customFileLang2" required>Seleccionar Imagen 1</label>
                        </div>
                        <h2>Subir informe de Radiografía 2</h2>
                        <div class="custom-file col-13">
                            <input type="file" class="custom-file-input" id="customFileLang3" lang="es" formControlName="PDF3" (change)="showPDF3($event)" required>
                            <label id="archivo3" class="custom-file-label" for="customFileLang3" required>Seleccionar Informe 2</label>
                        </div>
                        <br>
                        <br>
                        <h2>Subir imagen de Radiografía 2</h2>
                        <div class="custom-file col-13">
                            <input type="file" class="custom-file-input" id="customFileLang4" lang="es" formControlName="PDF4" (change)="showPDF4($event)" required>
                            <label id="archivo4" class="custom-file-label" for="customFileLang4" required>Seleccionar Imagen 2</label>
                        </div>
                        <h2>Subir informe de Radiografía 3</h2>
                        <div class="custom-file col-13">
                            <input type="file" class="custom-file-input" id="customFileLang5" lang="es" formControlName="PDF5" (change)="showPDF5($event)" required>
                            <label id="archivo5" class="custom-file-label" for="customFileLang5" required>Seleccionar Informe 3</label>
                        </div>
                        <br>
                        <br>
                        <h2>Subir imagen de Radiografía 3</h2>
                        <div class="custom-file col-13">
                            <input type="file" class="custom-file-input" id="customFileLang6" lang="es" formControlName="PDF6" (change)="showPDF6($event)" required>
                            <label id="archivo6" class="custom-file-label" for="customFileLang6" required>Seleccionar Imagen 3</label>
                        </div>
                    </form>
                    <button type="submit" class="btn btn-primary btn-lg btn-block mt-5">Guardar</button>
                    <br>
                </form>
            </div>
        </div>
    </div>
</div>