import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InicioComponent } from './components/inicio/inicio.component';
import { PrimeraComponent } from './components/primera/primera.component';
import { SubsecuenteComponent } from './components/subsecuente/subsecuente.component';
import { ReporteDiarioComponent } from './components/reporte-diario/reporte-diario.component';
import { LaboratorioComponent } from './components/laboratorio/laboratorio.component';
import { FarmaciaComponent } from './components/farmacia/farmacia.component';
import { PacientesComponent } from './components/pacientes/pacientes.component';
import { LoginComponent } from './components/login/login.component';
import { ActualizarPacienteComponent } from './components/actualizar-paciente/actualizar-paciente.component';
import { ActualizarProductoComponent } from './components/actualizar-producto/actualizar-producto.component';
import { ActualizarEvolucionComponent } from './components/actualizar-evolucion/actualizar-evolucion.component';
import { CieComponent } from './components/cie/cie.component';
import { PerfilComponent } from './components/perfil/perfil.component';
import { RegisterComponent } from './components/register/register.component';
import { ActualizarCieComponent } from './components/actualizar-cie/actualizar-cie.component';
import { CertificadoMedicoComponent } from './components/certificado-medico/certificado-medico.component';
import { CanAdminGuard } from './guards/can-admin.guard';
import { LoginLaboratorioComponent } from './components/login-laboratorio/login-laboratorio.component';
import { LaboratorioOnlineComponent } from './components/laboratorio-online/laboratorio-online.component';
import { LaboratorioUploadComponent } from './components/laboratorio-upload/laboratorio-upload.component';
import { RayosxUploadComponent } from './components/rayosx-upload/rayosx-upload.component';
import { RegisterLaboratorioComponent } from './components/register-laboratorio/register-laboratorio.component';
import { RegisterRayosxComponent } from './components/register-rayosx/register-rayosx.component';
import { RegisterEmpresaComponent } from './components/register-empresa/register-empresa.component';
import { EmpresaOnlineComponent } from './components/empresa-online/empresa-online.component';
import { RegisterAudiometriaComponent } from './components/register-audiometria/register-audiometria.component';
import { RegisterOptometriaComponent } from './components/register-optometria/register-optometria.component';
import { AudiometriaUploadComponent } from './components/audiometria-upload/audiometria-upload.component';
import { OptometriaUploadComponent } from './components/optometria-upload/optometria-upload.component';


const routes: Routes = [
  {path: 'inicio', component: InicioComponent},
  
  {path: 'primera/:id', component: PrimeraComponent, canActivate: [CanAdminGuard],}, //canActivate para restringir el acceso si el usuario no esta logeado
  {path: 'subsecuente/:id', component: SubsecuenteComponent, canActivate: [CanAdminGuard],},
  {path: 'reporte-diario', component: ReporteDiarioComponent, canActivate: [CanAdminGuard],},
  {path: 'laboratorio/:id', component: LaboratorioComponent, canActivate: [CanAdminGuard],},
  {path: 'farmacia/:id', component: FarmaciaComponent, canActivate: [CanAdminGuard],},
  {path: 'pacientes/:id', component: PacientesComponent, canActivate: [CanAdminGuard],},
  {path: 'actualizar-paciente/:id', component: ActualizarPacienteComponent, canActivate: [CanAdminGuard],},
  {path: 'certificado-medico/:id', component: CertificadoMedicoComponent, canActivate: [CanAdminGuard],},
  {path: 'actualizar-producto/:id', component: ActualizarProductoComponent, canActivate: [CanAdminGuard],},
  {path: 'actualizar-evolucion/:id', component: ActualizarEvolucionComponent, canActivate: [CanAdminGuard],},
  {path: 'actualizar-cie/:id', component: ActualizarCieComponent, canActivate: [CanAdminGuard],},
  {path: 'cie', component: CieComponent, canActivate: [CanAdminGuard],},
  {path: 'perfil', component: PerfilComponent, canActivate: [CanAdminGuard],},
  {path: 'register', component: RegisterComponent, canActivate: [CanAdminGuard],},
  
  {path: 'login', component: LoginComponent},
  {path: 'login-laboratorio', component: LoginLaboratorioComponent},
  
  {path: 'laboratorio-online/:id', component: LaboratorioOnlineComponent},
  {path: 'empresa-online/:id', component: EmpresaOnlineComponent},
  {path: 'upload-clinicaLab', component: LaboratorioUploadComponent},
  {path: 'upload-radiografiaLab', component: RayosxUploadComponent},
  {path: 'upload-audiometriaLab', component: AudiometriaUploadComponent},
  {path: 'upload-optometriaLab', component: OptometriaUploadComponent},

  {path: 'register-clinicaLab', component: RegisterLaboratorioComponent},
  {path: 'register-radiografiaLab', component: RegisterRayosxComponent},
  {path: 'register-empresaLab', component: RegisterEmpresaComponent},
  {path: 'register-audiometriaLab', component: RegisterAudiometriaComponent},
  {path: 'register-optometriaLab', component: RegisterOptometriaComponent},

  {path: '', redirectTo: '/inicio', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
