<div id="login" class="container">
    <div id="contenedor" class="row">
      <div class="col-12 p-5">
        <div class="row">
          <div class="col-md-6 mx-auto mt-5">
            <div class="card">
              <div class="card-body">
                <h1 class="text-center">REGISTRO DE SERVICIO DE RADIOGRAFÍA</h1>
                <!-- <h1 class="text-center">REGISTRO DE LABORATORIO DE RADIOGRAFÍAS</h1> -->
                <form (ngSubmit)="onRegister()" class="mt-5">
                  <div class="form-group">
                    <label for="name">Nombre</label>
                    <input type="text"
                    class="form-control"
                    placeholder=""
                    [(ngModel)]="radiografiaLab.nombre"
                    name="nombre"
                    id="name"
                    required >
                  </div>
                  <div class="form-group">
                      <label for="ruc">Cedula/RUC</label>
                      <input type="text"
                      class="form-control"
                      placeholder=""
                      [(ngModel)]="radiografiaLab.ruc"
                      name="ruc"
                      id="ruc"
                      required >
                  </div>
                  <div class="form-group">
                    <label for="direccion">Dirección</label>
                    <input type="text"
                    class="form-control"
                    placeholder=""
                    [(ngModel)]="radiografiaLab.direccion"
                    name="direccion"
                    id="direccion"
                    required >
                  </div>
                  <div class="form-group">
                    <label for="telefono">teléfono</label>
                    <input type="text"
                    class="form-control"
                    placeholder=""
                    [(ngModel)]="radiografiaLab.telefono"
                    name="telefono"
                    id="telefono"
                    required >
                  </div>
                  <div class="form-group">
                    <label for="password">Contraseña</label>
                    <input type="password"
                    class="form-control"
                    placeholder=""
                    [(ngModel)]="radiografiaLab.password"
                    name="password"
                    id="password"
                    required >
                  </div>
                  <button type="submit" class="btn btn-primary btn-lg btn-block">Registrar</button>
                </form>
              </div>
            </div>
          </div>
        </div>      
      </div>
    </div>
  </div>
