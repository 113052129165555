<div id="certificado-medico" class="container">
    <div class="row">
        <div class="col-12 p-5">
            <br><br>
            <div id="contenedor" class="bg-white p-5">
              <div class="row">
                <div class="col">
                  <br><br><br><br><br>
                  <label>Diagnostico:</label>
                  <input type="text" class="form-control" [(ngModel)]="codigoDiagnostico" placeholder="Diagnostico" list="codigos" (change)="mostrarDatos()">
                  <datalist id="codigos">
                    <option *ngFor="let cie of cies" value="{{cie.descripcion}} ({{ cie.codigo }})">
                  </datalist>
                  <br><br>
                  <label>Días de Resposo:</label>
                  <input type="text" class="form-control"  placeholder="Duración" list="duracion" [(ngModel)]="diasReposo" (change)="mostrarDatos()">
                  <datalist id="duracion">
                    <option value="uno (1) día">
                    <option value="dos (2) días">
                    <option value="cuatro (4) días">
                    <option value="cinco (5) días">
                    <option value="seis (6) días">
                    <option value="siete (7) días">
                    <option value="ocho (8) días">
                    <option value="nueve (9) días">
                    <option value="diez (10) días">
                    <option value="once (11) días">
                    <option value="doce (12) días">
                    <option value="trece (13) días">
                    <option value="catorce (14) días">
                    <option value="quince (15) días">
                    <option value="dieciséis (16) días">
                    <option value="diecisiete (17) días">
                    <option value="dieciocho (18) días">
                    <option value="diecinueve (19) días">
                    <option value="veinte (20) días">
                  </datalist>
                  <br><br>
                  <label>Desde:</label>
                  <input type="text" class="form-control" placeholder="Día" list="dias" [(ngModel)]="diaDesde" (change)="mostrarDatos()">
                  <datalist id="dias">
                    <option value="uno (1)">
                    <option value="dos (2)">
                    <option value="tres (3)">
                    <option value="cuatro (4)">
                    <option value="cinco (5)">
                    <option value="seis (6)">
                    <option value="siete (7)">
                    <option value="ocho (8)">
                    <option value="nueve (9)">
                    <option value="diez (10)">
                    <option value="once (11)">
                    <option value="doce (12)">
                    <option value="trece (13)">
                    <option value="catorce (14)">
                    <option value="quince (15)">
                    <option value="dieciséis (16)">
                    <option value="diecisiete (17)">
                    <option value="dieciocho (18)">
                    <option value="diecinueve (19)">
                    <option value="veinte (20)">
                    <option value="veintiuno (21)">
                    <option value="veintidós (22)">
                    <option value="veintitrés (23)">
                    <option value="veinticuatro (24)">
                    <option value="veinticinco (25)">
                    <option value="veintiséis (26)">
                    <option value="veintisiete (27)">
                    <option value="veintiocho (28)">
                    <option value="veintinueve (29)">
                    <option value="treinta (30)">
                    <option value="treinta y uno (31)">
                  </datalist>
                  <br>
                  <input type="text" class="form-control" placeholder="Mes" list="meses" [(ngModel)]="mesDesde" (change)="mostrarDatos()">
                  <datalist id="meses">
                    <option value="enero">
                    <option value="febrero">
                    <option value="marzo">
                    <option value="abril">
                    <option value="mayo">
                    <option value="junio">
                    <option value="julio">
                    <option value="agosto">
                    <option value="septiembre">
                    <option value="octubre">
                    <option value="noviembre">
                    <option value="diiembre">
                  </datalist>
                  <br>
                  <input type="text" class="form-control" placeholder="Año" [(ngModel)]="yearDesde" (change)="mostrarDatos()">
                  <br><br>
                  <label>Hasta:</label>
                  <input type="text" class="form-control" placeholder="Día" list="dias" [(ngModel)]="diaHasta" (change)="mostrarDatos()">
                  <br>
                  <input type="text" class="form-control" placeholder="Mes" list="meses" [(ngModel)]="mesHasta" (change)="mostrarDatos()">
                  <br>
                  <input type="text" class="form-control" placeholder="Año" [(ngModel)]="yearHasta" (change)="mostrarDatos()">
                  <br>
                  <button (click)="downloadPDF()" class="btn btn-primary container">Descargar Certificado Médico</button>
                </div>
                <div class="col-7">
                  <div id="imprimir" #imprimir class="pl-5">
                    <br>
                    <div class="text-right">
                      <img src="../assets/img/logo-certificado-medico.png" width="40%">
                    </div>
                    <h1></h1>
                    <br>
                    <p class="text-right">Quito, <span id="fechas" #fec></span></p>
                    <br><br><br>
                    <h2 class="text-center"><strong>CERTIFICADO MÉDICO</strong></h2>
                    <br><br>
                    <p class="text-justify">
                      A través de la presente me permito certificar que <label *ngIf="paciente.sexo">el</label><label *ngIf="!paciente.sexo">la</label> paciente <strong>{{paciente.nombres}} {{paciente.apellidos}}</strong> de {{paciente.fecha_nacimiento | edad}} de edad, con CI. {{paciente.cedula}}, acudió a este establecimiento de salud por motivo de
                      <label id="codigoD" >-------</label>
                      , por lo que necesita <label id="diasR" >-------</label> de reposo, desde el <label id="diaD" >-------</label> de <label id="mesD" >-------</label> del <label id="yearD" >-------</label>                 
                      hasta el <label id="diaH" >-------</label> de <label id="mesH" >-------</label> del <label id="yearH" >-------</label>.
                    </p>
                    <br>
                    <p>Es todo cuanto puedo certificar</p>
                    <br>
                    <p>Atentamente</p>
                    <br><br>
                    <p>{{ usuario.displayName }}<br>
                    {{ usuario.especialidad }}<br>
                    Código médico: {{ usuario.cedula }}<br>
                    Correo electrónico: {{ usuario.email }}
                    </p>
                    <br>
                    <h1></h1>
                    <div class="text-center">
                      <img src="../assets/img/footer.png" width="100%">
                    </div>
                  </div>
                  <br>
                </div>
              </div> 
            </div>
        </div>
    </div>
</div>
        



