import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(value: any, arg: any): any {
    if (arg === '' || arg.length < 1) return value;
    const resultPosts = [];
    for (const heroe of value) {
      if (heroe.nombres.toLowerCase().indexOf(arg.toLowerCase()) > -1) {
        resultPosts.push(heroe);
      };
      if (heroe.cedula.indexOf(arg) > -1) {
        resultPosts.push(heroe);
      };
    };
    return resultPosts;
  }


}