import { Component, OnInit } from '@angular/core';
import { PacienteModel } from 'src/app/models/paciente.model';
import { PrimeraService } from '../../services/primera.service';
import { FormulariosService } from '../../services/formularios.service';
import { EmpresaLabModel } from '../../models/empresaLab.model';
import { FormularioModel } from '../../models/formulario.model';
import { NgForm, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import * as moment from 'moment';


@Component({
  selector: 'app-laboratorio-upload',
  templateUrl: './laboratorio-upload.component.html',
  styleUrls: ['./laboratorio-upload.component.css']
})
export class LaboratorioUploadComponent implements OnInit {

  paciente: PacienteModel = new PacienteModel();
  empresas: EmpresaLabModel[];
  elFecha: string;
  url: any;
  pdfFile= false;
  private pdf1: any;
  private pdf2: any;
  cedula: string;
  selectedValue: string;

  constructor(private primeraService: PrimeraService, private router: Router, private readonly formulariosService: FormulariosService) { }

  ngOnInit(): void {
    moment.locale('es');
    var nowDate = moment(new Date());
    this.elFecha = nowDate.format('DD-MM-YYYY');

    this.primeraService.getEmpresaLab().subscribe( resp => {
      this.empresas = resp;
    });
    
  }

  guardar(form: NgForm){
    this.paciente.fecha_registro=this.elFecha;

    if ( form.invalid ) {
      // console.log('Formulario no válido');
      Swal.fire({
        title: 'Formulario no válido',
        text: 'Ingrese los datos',
        icon: 'error',
        allowOutsideClick: false
      });
      return
    }
    if ( this.pdfFile==true ) {
      
      Swal.fire({
        title: 'Espere',
        text: 'Guardando información',
        icon: 'info',
        allowOutsideClick: false
      });
  
      //validar si ya existe el paciente
      let cedula;
      let empresa;
      let peticion: Observable<any>;
      
      this.primeraService.getPacientes().subscribe(res => {
        cedula = res.filter(item => item.cedula ==  this.paciente.cedula);
        if(cedula.length==0){
          Swal.showLoading();
          peticion = this.primeraService.crearPaciente( this.paciente );

          peticion.subscribe( resp => {
            Swal.fire({
              title: 'Paciente Nuevo Agregado',
              text: 'Documento subido correctamente',
              icon: 'success'
            }).then((result) => {
              window.location.reload();
            });
          });
        }else if (cedula.length>0) {
          empresa = cedula.filter(item => item.empresa ==  this.paciente.empresa);
          if(empresa.length==0){
            peticion = this.primeraService.crearPaciente( this.paciente );
            peticion.subscribe( resp => {
              Swal.fire({
                title: 'Documento Agregado',
                text: `Al paciente con cedula: ${this.paciente.cedula}`,
                icon: 'success'
              }).then((result) => {
                window.location.reload();
              });
            });
          }

          Swal.fire({
            title: 'Documento Agregado',
            text: `Al paciente con cedula: ${this.paciente.cedula}`,
            icon: 'success'
          }).then((result) => {
            window.location.reload();
          });
        } 

      });

      try {
        this.formulariosService.uploadLabExam(this.pdf1, this.paciente.cedula, 'Examen-Laboratorio-1');
      } catch (error) {
        console.log(error);
      }
      try {
        this.formulariosService.uploadLabExam(this.pdf2, this.paciente.cedula, 'Examen-Laboratorio-2');
      } catch (error) {
        console.log(error);
      }

    }else{
      Swal.fire({
        title: 'Formulario no válido',
        text: 'Suba el archivo PDF',
        icon: 'error',
        confirmButtonText: "Aceptar",
        allowOutsideClick: false
      });
    }
  }

  showPDF1(event: any): void {
    this.pdfFile=true;
    document.getElementById("archivo1").innerHTML="Examen subido";
    this.pdf1 = event.target.files[0];
  }

  showPDF2(event: any): void {
    this.pdfFile=true;
    document.getElementById("archivo2").innerHTML="Examen subido";
    this.pdf2 = event.target.files[0];
  }

  public newPostForm = new FormGroup({
    PDF1:new FormControl('', Validators.required),
    PDF2:new FormControl('', Validators.required),
  });

}
