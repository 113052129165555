<div id="perfil" class="container">
  <div class="row pt-5 mt-5">
    <div class="col-6 p-5 mt-5 mx-auto">
      <div id="contenedor" class="animated fadeIn faster p-3 text-center text-justify" >
        <h1 class="text-center"><strong>{{ usuario.displayName }}</strong></h1>
        <br>
        <p >ESPECIALIDAD: {{ usuario.especialidad }}</p>
        <p >CEDULA: {{ usuario.cedula }}</p>
        <p >CORREO: {{ usuario.email }}</p>
        <br>
        <div class="col">
          <button class="btn btn-dark mr-1 text-center container" (click)="registerDoctor()">Registrar Doctor</button>
          <button class="btn btn-dark mr-1 text-center mt-2 container" (click)="registerLaboratorio()">Registrar Laboratorio Clínico</button>
          <button class="btn btn-dark mr-1 text-center mt-2 container" (click)="registerRayosX()">Registrar Servicio de Radiografía</button>
          <button class="btn btn-dark mr-1 text-center mt-2 container" (click)="registerAudiometria()">Registrar Servicio de Audiometría</button>
          <button class="btn btn-dark mr-1 text-center mt-2 container" (click)="registerOptometria()">Registrar Servicio de Optometría</button>
          <button class="btn btn-dark mr-1 text-center mt-2 container" (click)="registerEmpresa()">Registrar Empresa</button>
        </div>

      </div>      
    </div>
  </div>
</div>