<div class="container">
    <div class="mt-5 pt-5">
        <div id="contenedor" class="p-3 my-5">
            <div class="col">
                <h1 class="text-center">Examen de Laboratorio</h1>
                <form (ngSubmit)="guardar( f )" #f="ngForm">
                    <h2>Ingrese la cedula del paciente</h2>
                    <input type="text"
                        class="form-control"
                        placeholder="Cedula"
                        [(ngModel)]="paciente.cedula"
                        name="nombres"
                        id="nombres"
                        required >
                    <br>
                    <h2>Ingrese la Empresa/Laboratorio</h2>
                    <select class="form-control"  [(ngModel)]="paciente.empresa" name="empresa" id="empresa" required>
                        <option value="undefined" hidden selected>Selecciona una Empresa o Laboratorio</option>
                        <option *ngFor="let empresa of empresas" [ngValue]="empresa.nombre">{{empresa.nombre}}</option>
                    </select>
                    <br>

                    <br>
                    <!-- <h2>Seleccione el tipo de documento</h2>
                    <div class="row">
                        <div class="m-3">
                            <input class="mr-2" type="radio" name="lab" id="paciente" value="Examen-Laboratorio" [(ngModel)]="opcionSeleccionado">
                            <label  for="paciente">Examen de laboratorio</label>
                        </div>
                        <div class="m-3">
                            <input class="mr-2" type="radio" name="lab" id="empresa" value="Analisis-Clinico" [(ngModel)]="opcionSeleccionado">
                            <label class="ml-1" for="empresa">Analisis clinico</label>
                        </div>
                        <div class="m-3">
                            <input class="mr-2" type="radio" name="lab" id="otro" value="Otro" [(ngModel)]="opcionSeleccionado">
                            <label class="ml-1" for="otro">Otro</label>
                        </div>
                    </div> -->
                    <form [formGroup]="newPostForm">
                        <h2>Subir examen de laboratorio 1</h2>
                        <div class="custom-file col-13">
                            <input type="file" class="custom-file-input" id="customFileLang1" lang="es" formControlName="PDF1" (change)="showPDF1($event)" required>
                            <label id="archivo1" class="custom-file-label" for="customFileLang1" required>Seleccionar Archivo</label>
                        </div>
                        <br>
                        <br>
                        <h2>Subir examen de laboratorio 2</h2>
                        <div class="custom-file col-13">
                            <input type="file" class="custom-file-input" id="customFileLang2" lang="es" formControlName="PDF2" (change)="showPDF2($event)" required>
                            <label id="archivo2" class="custom-file-label" for="customFileLang2" required>Seleccionar Archivo</label>
                        </div>
                    </form>
                    <button type="submit" class="btn btn-primary btn-lg btn-block mt-5">Guardar</button>
                    <br>
                </form>
            </div>
        </div>
    </div>
</div>
    

