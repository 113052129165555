import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { InicioComponent } from './components/inicio/inicio.component';
import { PrimeraComponent } from './components/primera/primera.component';
import { ReporteDiarioComponent } from './components/reporte-diario/reporte-diario.component';
import { SubsecuenteComponent } from './components/subsecuente/subsecuente.component';
import { LaboratorioComponent } from './components/laboratorio/laboratorio.component';
import { FarmaciaComponent } from './components/farmacia/farmacia.component';
import { PacientesComponent } from './components/pacientes/pacientes.component';
import { LoginComponent } from './components/login/login.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { ActualizarPacienteComponent } from './components/actualizar-paciente/actualizar-paciente.component';
import { ActualizarProductoComponent } from './components/actualizar-producto/actualizar-producto.component';
import { ActualizarEvolucionComponent } from './components/actualizar-evolucion/actualizar-evolucion.component';
import { CieComponent } from './components/cie/cie.component';
import { ActualizarCieComponent } from './components/actualizar-cie/actualizar-cie.component';
import { CertificadoMedicoComponent } from './components/certificado-medico/certificado-medico.component';
import { PerfilComponent } from './components/perfil/perfil.component';
import { RegisterComponent } from './components/register/register.component';

import { SafePipe } from './pipes/safe.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { FilterEvoPipe } from './pipes/filter-evo.pipe';
import { FilterDatePipe } from './pipes/filter-date.pipe';
import { EdadPipe } from './pipes/edad.pipe';
import { FilterExaPipe } from './pipes/filter-exa.pipe';
import { LoginLaboratorioComponent } from './components/login-laboratorio/login-laboratorio.component';
import { LaboratorioOnlineComponent } from './components/laboratorio-online/laboratorio-online.component';
import { LaboratorioUploadComponent } from './components/laboratorio-upload/laboratorio-upload.component';
import { RayosxUploadComponent } from './components/rayosx-upload/rayosx-upload.component';
import { RegisterLaboratorioComponent } from './components/register-laboratorio/register-laboratorio.component';
import { RegisterRayosxComponent } from './components/register-rayosx/register-rayosx.component';
import { RegisterEmpresaComponent } from './components/register-empresa/register-empresa.component';
import { EmpresaOnlineComponent } from './components/empresa-online/empresa-online.component';
import { FilterEmpresaPipe } from './pipes/filter-empresa.pipe';
import { OptometriaUploadComponent } from './components/optometria-upload/optometria-upload.component';
import { RegisterOptometriaComponent } from './components/register-optometria/register-optometria.component';
import { AudiometriaUploadComponent } from './components/audiometria-upload/audiometria-upload.component';
import { RegisterAudiometriaComponent } from './components/register-audiometria/register-audiometria.component';
// import { FileSaverModule } from 'ngx-filesaver';



const config = {
  apiKey: "AIzaSyB_pzNqss71cKi_MFzq0bTXK4Vc3VfR29k",
  authDomain: "clinica-581fe.firebaseapp.com",
  databaseURL: "https://clinica-581fe.firebaseio.com",
  projectId: "clinica-581fe",
  storageBucket: "clinica-581fe.appspot.com",
  messagingSenderId: "214817100056",
  appId: "1:214817100056:web:f1e7b359ba726777480e6d",
  measurementId: "G-CEMPDM880W"
};



@NgModule({
  declarations: [
    AppComponent,
    InicioComponent,
    PrimeraComponent,
    ReporteDiarioComponent,
    SubsecuenteComponent,
    LaboratorioComponent,
    SafePipe,
    FarmaciaComponent,
    FilterPipe,
    FilterEvoPipe,
    FilterDatePipe,
    PacientesComponent,
    EdadPipe,
    LoginComponent,
    NavbarComponent,
    ActualizarPacienteComponent,
    ActualizarProductoComponent,
    ActualizarEvolucionComponent,
    CieComponent,
    ActualizarCieComponent,
    CertificadoMedicoComponent,
    FilterExaPipe,
    PerfilComponent,
    RegisterComponent,
    LoginLaboratorioComponent,
    LaboratorioOnlineComponent,
    LaboratorioUploadComponent,
    RayosxUploadComponent,
    RegisterLaboratorioComponent,
    RegisterRayosxComponent,
    RegisterEmpresaComponent,
    EmpresaOnlineComponent,
    FilterEmpresaPipe,
    OptometriaUploadComponent,
    RegisterOptometriaComponent,
    AudiometriaUploadComponent,
    RegisterAudiometriaComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(config),
    AngularFirestoreModule, 
    AngularFireAuthModule, 
    AngularFireStorageModule,
    BrowserAnimationsModule,
    // FileSaverModule  
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
