export class PrimeraModel {
    id: string;
    cedula: string;
    nombres: string;
    apellidos: string;
    sexo: boolean;
    direccion: string;
    telefono: string;
    correo: string;
    diagnostico: string;
    fecha_nacimiento: string;
    fecha_registro: string;
    constructor() {
        this.sexo = true;
    }
}