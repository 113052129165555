import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterEvo'
})
export class FilterEvoPipe implements PipeTransform {

  transform(value: any, arg: any): any {
    const resultPosts = [];
    for (const subsecuente of value) {
      if (subsecuente.identificador.indexOf(arg) > -1) {
        resultPosts.push(subsecuente);
      };
    };
    return resultPosts; 
  }

}
