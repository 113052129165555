import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LaboratorioModel } from 'src/app/models/laboratorio.model';
import { LaboratorioService } from 'src/app/services/laboratorio.service';
import { FormulariosService } from '../../services/formularios.service';
import { CieModel } from '../../models/cie.model';
import { CieService } from '../../services/cie.service';
import { Observable } from 'rxjs';
import { PrimeraModel } from '../../models/primera.model';
import { PrimeraService } from '../../services/primera.service';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import Swal from 'sweetalert2';
import * as moment from 'moment';

@Component({
  selector: 'app-primera',
  templateUrl: './primera.component.html',
  styleUrls: ['./primera.component.css']
})
export class PrimeraComponent implements OnInit {

  paciente: PrimeraModel = new PrimeraModel();
  examenes: LaboratorioModel[];
  cie: CieModel = new CieModel();
  cies: CieModel[];

  index: string ="f2";
  opcionSeleccionado: string ="f2";
  
  nombre: string;
  apellido: string;
  cedula: string;

  elFecha: string;

  constructor( private primeraService: PrimeraService,
               private laboratorioService: LaboratorioService,
               private cieService: CieService,
               private route: ActivatedRoute,
               private readonly formulariosService: FormulariosService, 
               private router: Router) { }

  ngOnInit() {

    const id = this.route.snapshot.paramMap.get('id');

    if ( id !== 'nuevo' ) {

      this.primeraService.getHeroe( id ).subscribe( (resp: PrimeraModel) => {
        this.paciente = resp;
        this.paciente.id = id;
      });

    }

    this.cieService.getCies().subscribe( resp => {
      this.cies = resp;
    });

    moment.locale('es');
    var nowDate = moment(new Date());
    this.elFecha = nowDate.format('dddd DD [de] MMMM [de] YYYY');
    
  }

  @ViewChild('content') content: ElementRef;
  public downloadPDF(){
    
    window["html2canvas"] = html2canvas;
    var doc = new jsPDF("l","pt",[470, 870]);

    doc.html(document.getElementById('imprimir') , {
       callback: function (doc) {
         doc.save();
       }
    });

  }

  guardar( form: NgForm ) {

    this.paciente.fecha_registro=this.elFecha;
    
    if ( form.invalid ) {
      console.log('Formulario no válido');
      Swal.fire({
        title: 'Formulario no válido',
        text: 'Ingrese los datos',
        icon: 'error',
        allowOutsideClick: false
      });
      return;
    }

    Swal.fire({
      title: 'Espere',
      text: 'Guardando información',
      icon: 'info',
      allowOutsideClick: false
    });

    Swal.showLoading();

    let peticion: Observable<any>;

    if ( this.paciente.id ) {
      peticion = this.primeraService.actualizarHeroe( this.paciente );
    } else {
      peticion = this.primeraService.crearHeroe( this.paciente );
    }

    peticion.subscribe( resp => {

      Swal.fire({
        title: this.paciente.nombres,
        text: 'Se actualizó correctamente',
        icon: 'success'
      }).then((result) => {
        this.router.navigate(['/reporte-diario']);
      });

    });

  }

}
