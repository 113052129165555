import { Component, OnInit } from '@angular/core';
import { FormulariosService } from '../../services/formularios.service';
import Swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-laboratorio-online',
  templateUrl: './laboratorio-online.component.html',
  styleUrls: ['./laboratorio-online.component.css']
})
export class LaboratorioOnlineComponent implements OnInit {

  opcionSeleccionado: string;
  urlPDF: string;
 
  url: any;
  mostrarFrm=false;
  public cedula:string;



  constructor(private readonly formulariosService: FormulariosService,
              private route: ActivatedRoute,
              private http: HttpClient,  
    ) { }

  ngOnInit(): void {
    this.cedula = this.route.snapshot.paramMap.get('id');
  }

  change(){

    this.url=this.formulariosService.getLabExam(this.cedula, this.opcionSeleccionado);
    
    this.url.getDownloadURL().subscribe(url => {
      this.urlPDF = url;
      this.mostrarFrm=true;
    }, err => {
      this.mostrarFrm=false;
      Swal.fire({
        title: 'Archivo no encontrado',
        text: 'No tiene registrado este archivo',
        icon: 'error',
        allowOutsideClick: false
      });
      return;
    });

  }

  async downloadBrochure() {
    
    try {
      const res = await this.http.get(this.urlPDF, { responseType: 'blob' }).toPromise();
      this.downloadFile(res);
    } catch (e) {
      console.log(e.body.message);
    }
  }

  downloadFile(data) {
    const url = window.URL.createObjectURL(data);
    const e = document.createElement('a');
    e.href = url;
    e.download = 'Examen';
    // e.download = url.substr(url.lastIndexOf('/') + 1);
    document.body.appendChild(e);
    e.click();
    document.body.removeChild(e);
  }


}
