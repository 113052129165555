import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { FormularioModel } from '../models/formulario.model';
import { FileModel } from '../models/file.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FormulariosService {

  private formsCollection: AngularFirestoreCollection<FormularioModel>;
  private filePath: any;

  constructor(private afs: AngularFirestore,
              private storage: AngularFireStorage) { 
              this.formsCollection = afs.collection<FormularioModel>('Formularios');
              }

  public getAllPosts(): Observable<FormularioModel[]> {
    return this.formsCollection.snapshotChanges().pipe(map(actions => actions.map(a => {
      const data = a.payload.doc.data() as FormularioModel;
      const id = a.payload.doc.id;
      return { id, ...data };
    })));
  }

  public uploadPDF(form: FormularioModel, pdf: FileModel, nombre: string, apellido: string, pdfName) {
    this.filePath = `${nombre} ${apellido}/${pdfName}`;
    const fileRef = this.storage.ref(this.filePath);
    const task = this.storage.upload(this.filePath, pdf);
  }

  public getPDF(nombre: string, apellido: string, pdfName): any {
    this.filePath = `${nombre} ${apellido}/${pdfName}`;
    const fileRef = this.storage.ref(this.filePath);
    return fileRef;
  }

  public uploadLabExam(pdf: FileModel, celuda: string, pdfName) {
    this.filePath = `${celuda}/${pdfName}`;
    const fileRef = this.storage.ref(this.filePath);
    const task = this.storage.upload(this.filePath, pdf);

  }
  
  public getLabExam(cedula: string, pdfName): any {
    this.filePath = `${cedula}/${pdfName}`;
    const fileRef = this.storage.ref(this.filePath);
    return fileRef;
  }

}


