import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AudiometriaLabModel } from 'src/app/models/audiometriaLab.model';
import { PrimeraService } from '../../services/primera.service';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-register-audiometria',
  templateUrl: './register-audiometria.component.html',
  styleUrls: ['./register-audiometria.component.css']
})
export class RegisterAudiometriaComponent implements OnInit {

  audiometriaLab: AudiometriaLabModel = new AudiometriaLabModel();
  audiometriaLabs: AudiometriaLabModel[];

  constructor(private primeraService: PrimeraService, private router: Router) { }

  ngOnInit(): void {
  }

  onRegister() {
    let peticion: Observable<any>;
    peticion = this.primeraService.crearAudiometriaLab( this.audiometriaLab );
    peticion.subscribe( resp => {
      Swal.fire({
        title: this.audiometriaLab.nombre,
        text: 'Se creó correctamente',
        icon: 'success'
      }).then((result) => {
        this.router.navigate(['/login-laboratorio']);
      });
    });
   
  }  

}
