import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PacienteModel } from 'src/app/models/paciente.model';
import { ClinicaLabModel } from 'src/app/models/clinicaLab.model';
import { RadiografiaLabModel } from 'src/app/models/radiografiaLab.model';
import { EmpresaLabModel } from 'src/app/models/empresaLab.model';
import { AudiometriaLabModel } from 'src/app/models/audiometriaLab.model';
import { OptometriaLabModel } from 'src/app/models/optometriaLab.model';
import { PrimeraService } from '../../services/primera.service';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-login-laboratorio',
  templateUrl: './login-laboratorio.component.html',
  styleUrls: ['./login-laboratorio.component.css']
})
export class LoginLaboratorioComponent implements OnInit {

  // @Input() dataEntrante: any;

  check1: boolean = true;
  check2: boolean = false;
  check3: boolean = false;
  check4: boolean = false;
  check5: boolean = false;
  check6: boolean = false;
  input: PacienteModel= new PacienteModel();

  pacientes: PacienteModel[];
  clinicaLabs: ClinicaLabModel[];
  radiografiaLabs: RadiografiaLabModel[];
  empresaLabs: EmpresaLabModel[];
  audiometriaLabs: AudiometriaLabModel[];
  optometriaLabs: OptometriaLabModel[];

  cedula: string = '';
  password: string = '';

  clinicaLabCedula: string = '';
  clinicaLabPassword: string = '';

  radiografiaLabCedula: string = '';
  radiografiaLabPassword: string = '';

  empresaLabCedula: string = '';
  empresaLabPassword: string = '';

  audiometriaLabCedula: string = '';
  audiometriaLabPassword: string = '';

  optometriaLabCedula: string = '';
  optometriaLabPassword: string = '';
  
  constructor(private router: Router, private primeraService: PrimeraService) { }

  buscarCedula='';

  ngOnInit(): void {
    this.primeraService.getPacientes().subscribe( resp => {
      this.pacientes = resp;
    });
  }

  auth() {

    if(this.check2==false && this.check3==false && this.check4==false && this.check5==false && this.check6==false){
      this.primeraService.getPacientes().pipe(map(res => {
        // console.log( this.paciente.cedula);
        this.pacientes = res.filter(item => item.cedula === this.input.cedula);
        // console.log( this.pacientes);
        const length = this.pacientes.length;
        if(this.pacientes.length==0){
          Swal.fire({
            title: 'Error al iniciar sesión',
            text: 'Correo o contraseña incorrectos',
            icon: 'warning'
          });
        }else{
          // console.log( this.pacientes);
          if(this.pacientes.length==1){
            this.cedula= this.pacientes[0].cedula;
            this.password= this.pacientes[0].fecha_registro;
          }else{
            this.cedula= this.pacientes[length-1].cedula;
            this.password= this.pacientes[length-1].fecha_registro;
          }
          if(this.cedula==this.input.cedula && this.password==this.input.fecha_registro){
              this.router.navigate([`/laboratorio-online/${ this.input.cedula }`]);
          }else{
            Swal.fire({
              title: 'Error al iniciar sesión',
              text: 'Correo o contraseña incorrectos',
              icon: 'warning'
            });
          }   
        }
      })).subscribe();
    }

    if(this.check2){
      this.primeraService.getClinicaLab().pipe(map(res => {
        this.clinicaLabs = res.filter(item => item.ruc === this.input.cedula);
        const length = this.clinicaLabs.length;
        if(this.clinicaLabs.length==0){
          Swal.fire({
            title: 'Error al iniciar sesión',
            text: 'Correo o contraseña incorrectos',
            icon: 'warning'
          });
        }else{
          if(this.clinicaLabs.length==1){
            this.clinicaLabCedula= this.clinicaLabs[0].ruc;
            this.clinicaLabPassword= this.clinicaLabs[0].password;
          }else{
            this.clinicaLabCedula= this.clinicaLabs[length-1].ruc;
            this.clinicaLabPassword= this.clinicaLabs[length-1].password;
          }
          if(this.clinicaLabCedula==this.input.cedula && this.clinicaLabPassword==this.input.fecha_registro){
              this.router.navigate(['/upload-clinicaLab']);
          }else{
            Swal.fire({
              title: 'Error al iniciar sesión',
              text: 'Correo o contraseña incorrectos',
              icon: 'warning'
            });
          }   
        }
      })).subscribe();
    }

    if(this.check3){
      this.primeraService.getRadiografiaLab().pipe(map(res => {
        this.radiografiaLabs = res.filter(item => item.ruc === this.input.cedula);
        const length = this.radiografiaLabs.length;
        if(this.radiografiaLabs.length==0){
          Swal.fire({
            title: 'Error al iniciar sesión',
            text: 'Correo o contraseña incorrectos',
            icon: 'warning'
          });
        }else{
          if(this.radiografiaLabs.length==1){
            this.radiografiaLabCedula= this.radiografiaLabs[0].ruc;
            this.radiografiaLabPassword= this.radiografiaLabs[0].password;
          }else{
            this.radiografiaLabCedula= this.radiografiaLabs[length-1].ruc;
            this.radiografiaLabPassword= this.radiografiaLabs[length-1].password;
          }
          if(this.radiografiaLabCedula==this.input.cedula && this.radiografiaLabPassword==this.input.fecha_registro){
              this.router.navigate(['/upload-radiografiaLab']);
          }else{
            Swal.fire({
              title: 'Error al iniciar sesión',
              text: 'Correo o contraseña incorrectos',
              icon: 'warning'
            });
          }   
        }
      })).subscribe();
    }

    if(this.check4){
      this.primeraService.getEmpresaLab().pipe(map(res => {
        this.empresaLabs = res.filter(item => item.ruc === this.input.cedula);
        const length = this.empresaLabs.length;
        if(this.empresaLabs.length==0){
          Swal.fire({
            title: 'Error al iniciar sesión',
            text: 'Correo o contraseña incorrectos',
            icon: 'warning'
          });
        }else{
          if(this.empresaLabs.length==1){
            this.empresaLabCedula= this.empresaLabs[0].ruc;
            this.empresaLabPassword= this.empresaLabs[0].password;
          }else{
            this.empresaLabCedula= this.empresaLabs[length-1].ruc;
            this.empresaLabPassword= this.empresaLabs[length-1].password;
          }
          if(this.empresaLabCedula==this.input.cedula && this.empresaLabPassword==this.input.fecha_registro){
              this.router.navigate([`/empresa-online/${ this.input.cedula }`]);
          }else{
            Swal.fire({
              title: 'Error al iniciar sesión',
              text: 'Correo o contraseña incorrectos',
              icon: 'warning'
            });
          }   
        }
      })).subscribe();
    }

    if(this.check5){
      this.primeraService.getAudiometriaLab().pipe(map(res => {
        this.audiometriaLabs = res.filter(item => item.ruc === this.input.cedula);
        const length = this.audiometriaLabs.length;
        if(this.audiometriaLabs.length==0){
          Swal.fire({
            title: 'Error al iniciar sesión',
            text: 'Correo o contraseña incorrectos',
            icon: 'warning'
          });
        }else{
          if(this.audiometriaLabs.length==1){
            this.audiometriaLabCedula= this.audiometriaLabs[0].ruc;
            this.audiometriaLabPassword= this.audiometriaLabs[0].password;
          }else{
            this.audiometriaLabCedula= this.audiometriaLabs[length-1].ruc;
            this.audiometriaLabPassword= this.audiometriaLabs[length-1].password;
          }
          if(this.audiometriaLabCedula==this.input.cedula && this.audiometriaLabPassword==this.input.fecha_registro){
              this.router.navigate([`/upload-audiometriaLab`]);
          }else{
            Swal.fire({
              title: 'Error al iniciar sesión',
              text: 'Correo o contraseña incorrectos',
              icon: 'warning'
            });
          }   
        }
      })).subscribe();
    }
    
    if(this.check6){
      this.primeraService.getOptometriaLab().pipe(map(res => {
        this.optometriaLabs = res.filter(item => item.ruc === this.input.cedula);
        const length = this.optometriaLabs.length;
        if(this.optometriaLabs.length==0){
          Swal.fire({
            title: 'Error al iniciar sesión',
            text: 'Correo o contraseña incorrectos',
            icon: 'warning'
          });
        }else{
          if(this.optometriaLabs.length==1){
            this.optometriaLabCedula= this.optometriaLabs[0].ruc;
            this.optometriaLabPassword= this.optometriaLabs[0].password;
          }else{
            this.optometriaLabCedula= this.optometriaLabs[length-1].ruc;
            this.optometriaLabPassword= this.optometriaLabs[length-1].password;
          }
          if(this.optometriaLabCedula==this.input.cedula && this.optometriaLabPassword==this.input.fecha_registro){
              this.router.navigate([`/upload-optometriaLab`]);
          }else{
            Swal.fire({
              title: 'Error al iniciar sesión',
              text: 'Correo o contraseña incorrectos',
              icon: 'warning'
            });
          }   
        }
      })).subscribe();
    }     

  }

  changeRadio1(evt) {
    var target = evt.target;
    if (target.checked) {
      this.check1=true;
      this.check2=false;
      this.check3=false;
      this.check4=false;
      this.check5=false;
      this.check6=false;
    } else {
      this.check1=false;
    }
  }

  changeRadio2(evt) {
    var target = evt.target;
    if (target.checked) {
      this.check2=true;
      this.check1=false;
      this.check3=false;
      this.check4=false;
      this.check5=false;
      this.check6=false;
    } else {
      this.check2=false;
    }
  }

  changeRadio3(evt) {
    var target = evt.target;
    if (target.checked) {
      this.check3=true;
      this.check1=false;
      this.check2=false;
      this.check4=false;
      this.check5=false;
      this.check6=false;
    } else {
      this.check3=false;
    }
  }

  changeRadio4(evt) {
    var target = evt.target;
    if (target.checked) {
      this.check4=true;
      this.check1=false;
      this.check2=false;
      this.check3=false;
      this.check5=false;
      this.check6=false;
    } else {
      this.check4=false;
    }
  }

  changeRadio5(evt) {
    var target = evt.target;
    if (target.checked) {
      this.check5=true;
      this.check1=false;
      this.check2=false;
      this.check3=false;
      this.check4=false;
      this.check6=false;
    } else {
      this.check5=false;
    }
  }

  changeRadio6(evt) {
    var target = evt.target;
    if (target.checked) {
      this.check6=true;
      this.check1=false;
      this.check2=false;
      this.check3=false;
      this.check4=false;
      this.check5=false;
    } else {
      this.check6=false;
    }
  }

}
