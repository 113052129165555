import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterExa'
})
export class FilterExaPipe implements PipeTransform {

  transform(value: any, arg: any): any {
    const resultPosts = [];
    for (const examen of value) {
      if (examen.identificador.indexOf(arg) > -1) {
        resultPosts.push(examen);
      };
    };
    return resultPosts; 
  }

}
