import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FarmaciaModel } from '../../models/farmacia.model';
import { FarmaciaService } from 'src/app/services/farmacia.service';
import { AuthService } from 'src/app/services/auth.service';
import { User } from '../../models/user.model';
import { Observable } from 'rxjs';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import Swal from 'sweetalert2';
import * as moment from 'moment';

@Component({
  selector: 'app-farmacia',
  templateUrl: './farmacia.component.html',
  styleUrls: ['./farmacia.component.css']
})
export class FarmaciaComponent implements OnInit {
  
  public user$: Observable<User> = this.authSvc.afAuth.user;
  usuario: User;

  productos: FarmaciaModel[];

  verProd= false;

  medSeleccionado: string;
  med1: boolean = false;
  med2: boolean = false;
  med3: boolean = false;
  med4: boolean = false;
  med5: boolean = false;

  constructor(private farmaciaService: FarmaciaService,
              public authSvc: AuthService) { }

  ngOnInit(){

    this.user$.subscribe(user => {
    
      this.authSvc.getUser( user.uid ).subscribe( (resp: User) => {
        this.usuario = resp;
        this.usuario.uid =user.uid;
      });
    
    });

    this.farmaciaService.getHeroes().subscribe( resp => {
      this.productos = resp;
    });

    moment.locale('es');
    var nowDate = moment(new Date());
    var Fecha = document.querySelector("#fechas");
    Fecha.textContent = nowDate.format('dddd DD [de] MMMM [de] YYYY');
    
  }

  @ViewChild('content') content: ElementRef;
  public downloadPDF(){
    
    window["html2canvas"] = html2canvas;
    var doc = new jsPDF("p","pt","a4");

    doc.html(document.getElementById('imprimir') , {
       callback: function (doc) {
         doc.save();
       }
    });

  }

  borrarProducto( producto: FarmaciaModel, i: number ) {

    Swal.fire({
      title: '¿Está seguro?',
      text: `Está seguro que desea borrar a ${ producto.nombre_comercial }`,
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true
    }).then( resp => {

      if ( resp.value ) {
        this.productos.splice(i, 1);
        this.farmaciaService.borrarHeroe( producto.id ).subscribe();
      }

    });

  }

  verProductos(){
    this.verProd=true;
  }

  changeMed(){

    if(this.medSeleccionado=="m1"){
      this.med1=true;
      this.med2=false;
      this.med3=false;
      this.med4=false;
      this.med5=false;
    }

    if(this.medSeleccionado=="m2"){
      this.med1=true;
      this.med2=true;
      this.med3=false;
      this.med4=false;
      this.med5=false;
    }

    if(this.medSeleccionado=="m3"){
      this.med1=true;
      this.med2=true;
      this.med3=true;
      this.med4=false;
      this.med5=false;
    }

    if(this.medSeleccionado=="m4"){
      this.med1=true;
      this.med2=true;
      this.med3=true;
      this.med4=true;
      this.med5=false;
    }

    if(this.medSeleccionado=="m5"){
      this.med1=true;
      this.med2=true;
      this.med3=true;
      this.med4=true;
      this.med5=true;
    }
    
  }

}
