import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CieModel } from '../../models/cie.model';
import { CieService } from '../../services/cie.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cie',
  templateUrl: './cie.component.html',
  styleUrls: ['./cie.component.css']
})
export class CieComponent implements OnInit {

  cies: CieModel[];

  constructor(private cieService: CieService,
              private route: ActivatedRoute,) { }

  ngOnInit(): void {

    this.cieService.getCies().subscribe( resp => {
      this.cies = resp;
    });

  }

  borrarCie( cie: CieModel, i: number ) {

    Swal.fire({
      title: '¿Está seguro?',
      text: `Está seguro que desea borrar el codigo ${ cie.codigo }`,
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true
    }).then( resp => {

      if ( resp.value ) {
        this.cies.splice(i, 1);
        this.cieService.borrarCie( cie.id ).subscribe();
      }

    });
    
  }

}
