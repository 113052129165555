import { Injectable } from '@angular/core';
import { SubsecuenteModel } from '../models/subsecuente.model';
import { HttpClient } from '@angular/common/http';
import { map, delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SubsecuenteService {

  private url = 'https://clinica-581fe.firebaseio.com/';

  constructor( private http: HttpClient ) { }

  crearHeroe( subsecuente: SubsecuenteModel ) {

    return this.http.post(`${ this.url }/evolucion.json`, subsecuente).pipe(map( (resp: any) => {
      subsecuente.id = resp.name;
      return subsecuente;
    }));

  }

  actualizarHeroe( subsecuente: SubsecuenteModel ) {

    const subsecuenteTemp = {
      ...subsecuente
    };

    delete subsecuenteTemp.id;

    return this.http.put(`${ this.url }/evolucion/${ subsecuente.id }.json`, subsecuenteTemp);

  }

  borrarHeroe( id: string ) {

    return this.http.delete(`${ this.url }/evolucion/${ id }.json`);

  }


  getHeroe( id: string ) {

    return this.http.get(`${ this.url }/evolucion/${ id }.json`);

  }

  getHeroes() {
    return this.http.get(`${ this.url }/evolucion.json`).pipe(map( this.crearArreglo ), delay(0));
  }

  private crearArreglo( subsecuentesObj: object ) {

    const subsecuentes: SubsecuenteModel[] = [];

    Object.keys( subsecuentesObj ).forEach( key => {
      const subsecuente: SubsecuenteModel = subsecuentesObj[key];
      subsecuente.id = key;
      subsecuentes.push( subsecuente );
    });

    return subsecuentes;

  }

}
