<div id="cie" class="container">
    <div class="row mt-5">
      <div class="col-12 my-5">
        <br><br>
        <div id="contenedor" class="animated fadeIn faster p-4">
          <div class="row container">
            <div>
              <h1>LISTA DE CÓDIGOS CIE</h1>    
            </div>
            <div class="ml-auto">
              <button class="btn btn-primary" [routerLink]="['/actualizar-cie', 'nuevo']">Agregar Código CIE</button>
            </div>
          </div>
          <table class="table mt-3 animated fadeIn faster bg-secondary table-hover">
            <thead class="thead-dark">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Código</th>
                <th scope="col">Descripción</th>
                <th scope="col">Tools</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let cie of cies; let i = index">
                <th scope="row">{{ i + 1 }}</th>
                <td>{{ cie.codigo }}</td>
                <td>{{ cie.descripcion }}</td>
                <td>
                  <div class="row">
                    <button class="btn btn-info p-1 m-1" [routerLink]="['/actualizar-cie', cie.id ]">
                      <i class="fa fa-pencil"></i>
                    </button>
                    <button class="btn btn-danger p-1 m-1" (click)="borrarCie( cie, i ) ">
                      <i class="fa fa-trash"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>      
      </div>
    </div>
  </div>
  
