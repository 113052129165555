import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { LaboratorioService } from '../../services/laboratorio.service';
import { PrimeraService } from '../../services/primera.service';
import { PrimeraModel } from '../../models/primera.model';
import { ExamenModel } from '../../models/examen.model';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import * as moment from 'moment';

@Component({
  selector: 'app-laboratorio',
  templateUrl: './laboratorio.component.html',
  styleUrls: ['./laboratorio.component.css'],
  providers: [LaboratorioService]
})
export class LaboratorioComponent implements OnInit {

  private pdf: any;
  
  pacientes: PrimeraModel[];
  buscarNom = false;
  examen: ExamenModel = new ExamenModel();
  examenes: ExamenModel[];
  paciente: PrimeraModel= new PrimeraModel();
  cedulaEvolucion: string;
  nombreEvolucion: string;
  apellidoEvolucion: string;
  identificadorExa: string;
  buscarExa = false;
  urlPDF: string="";
  url: any;
  mostrarExa=false;
  elFecha: string;

  constructor(private readonly laboratorioService: LaboratorioService,
              private route: ActivatedRoute,
              private primeraService: PrimeraService) { }

  buscarLetra='';              

  public newPostForm = new FormGroup({
    nombre: new FormControl('', Validators.required),
    apellido: new FormControl('', Validators.required),
    cedula: new FormControl('', Validators.required),
    pdfPost: new FormControl('', Validators.required),
  });

  ngOnInit(): void {

    this.primeraService.getHeroes().subscribe( resp => {
      this.pacientes = resp;
    });

    this.laboratorioService.getExamenes().subscribe( resp => {
      this.examenes = resp;
    });

    const id = this.route.snapshot.paramMap.get('id');

    if ( id !== 'nuevo' ) {
      this.laboratorioService.getExamen( id ).subscribe( (resp: ExamenModel) => {
        this.examen = resp;
        this.examen.id = id;
      });
    }

    moment.locale('es');
    var nowDate = moment(new Date());
    this.elFecha = nowDate.format('DD [/] MM [/] YYYY');

  }

  handleImage(event: any): void {
    document.getElementById("archivo").innerHTML="PDF subido";
    this.pdf = event.target.files[0];
  }

  addNewPost(data: ExamenModel) {
    console.log('New post pdf', data);
    this.laboratorioService.uploadPDF(this.pdf, this.nombreEvolucion, this.apellidoEvolucion);
  }

  mostrarRegistros(texto: string){
    this.buscarNom=true;
    if(texto==""){
      this.buscarNom=false;
    }
  }

  buscarExamen(id: string, nombresPaciente: string, apellidosPaciente: string, cedulaPaciente: string,){
    this.buscarExa=true;
    this.buscarNom=false;
    
    this.cedulaEvolucion = cedulaPaciente;
    this.nombreEvolucion = nombresPaciente;
    this.apellidoEvolucion = apellidosPaciente;
    this.identificadorExa=this.nombreEvolucion+this.apellidoEvolucion+this.cedulaEvolucion;

    this.mostrarExa=false;
  }

  guardar() {

    console.log(this.nombreEvolucion+this.apellidoEvolucion+this.cedulaEvolucion);
    this.examen.identificador=this.nombreEvolucion+this.apellidoEvolucion+this.cedulaEvolucion;
    this.examen.fecha=this.elFecha;
    this.examen.nombrePdf=this.pdf.name;

    Swal.fire({
      title: 'Espere',
      text: 'Guardando información',
      icon: 'info',
      allowOutsideClick: false
    });

    Swal.showLoading();

    let peticion: Observable<any>;
    
    peticion = this.laboratorioService.crearExamen( this.examen );

    peticion.subscribe( resp => {

      this.examenes = resp;

      Swal.fire({
        title: 'Examen',
        text: 'Se actualizó correctamente',
        icon: 'success'
      }).then( resp => {
        document.location.reload();
      });

    });

  }

  borrarExamen( examen: ExamenModel, i: number ) {

    Swal.fire({
      title: '¿Está seguro?',
      text: `Está seguro que desea borrar el PDF ${ examen.nombrePdf }`,
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true
    }).then( resp => {

      if ( resp.isConfirmed ) {
        this.examenes.splice(i, 1);
        this.laboratorioService.borrarExamen( examen.id ).subscribe();

        this.laboratorioService.getExamen( examen.id ).subscribe( (resp: ExamenModel) => {
          this.examen = resp;
          this.laboratorioService.borrarPDF(this.examen.nombrePdf, this.nombreEvolucion, this.apellidoEvolucion);
        });
      }

      document.location.reload();

    });

    
    
  }

  guardarTodo(data: ExamenModel){
    this.addNewPost(data);
    this.guardar();
  }

  verExamen(examen: ExamenModel){

    this.mostrarExa=true;
    
    this.laboratorioService.getExamen( examen.id ).subscribe( (resp: ExamenModel) => {
      this.examen = resp;
      this.url=this.laboratorioService.getPDF(this.examen.nombrePdf, this.nombreEvolucion, this.apellidoEvolucion);
      this.url.getDownloadURL().subscribe(url => {
        this.urlPDF = url;
      });
    });
    
  }

}
