<div id="laboratorio" class="container">
    <div class="row mt-5">
      <div class="col-12 pt-5 my-5">
        <div id="contenedor" class="animated fadeIn faster p-3">
          <form class="container">
            <input class="form-control mr-sm-2" name="buscarLetra" type="text" placeholder="Buscar paciente" (keyup)="mostrarRegistros(buscarTexto.value)" #buscarTexto [(ngModel)]="buscarLetra">
          </form>
          <div class="pt-3" *ngIf="buscarNom===true">
            <table class="table mt-3 animated fadeIn faster bg-secondary table-hover">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Nombres</th>
                  <th scope="col">Apellidos</th>
                  <th scope="col">Cédula</th>
                  <th scope="col">Examenes</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let paciente of pacientes | filter:buscarLetra; let i = index">
                  <th scope="row">{{ i + 1 }}</th>
                  <td>{{ paciente.nombres }}</td>
                  <td>{{ paciente.apellidos }}</td>
                  <td>{{ paciente.cedula }}</td>
                  <td>
                    <button class="btn btn-dark mr-1" (click)="buscarExamen(paciente.id, paciente.nombres, paciente.apellidos, paciente.cedula)">
                      Examenes de Laboratorio
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="buscarExa===true" class="p-3">
            <br>
            <h1 class="text-center">{{ paciente.nombres }} {{ paciente.apellidos }}</h1>
            <br>
            <h2>Listado de Examenes</h2>
            <table class="table mt-3 animated fadeIn faster bg-secondary table-hover" >
              <thead class="thead-dark">
                <tr>
                  <th scope="col">Fecha</th>
                  <th scope="col">Nombre</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let examen of examenes | filterExa:identificadorExa">
                  <td>{{ examen.fecha }}</td>
                  <td>{{ examen.nombrePdf }}</td>
                  <td>
                    <button class="btn btn-dark p-1 m-1" (click)="verExamen( examen ) ">
                      Ver Examen
                    </button>
                    <button class="btn btn-danger p-1 m-1" (click)="borrarExamen( examen, i ) ">
                      <i class="fa fa-trash"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <h2>Agregar Examen</h2>
            <div class="container">
              <form class="row" [formGroup]="newPostForm" (ngSubmit)="guardarTodo(newPostForm.value)">
                <div class="custom-file col">
                  <input type="file" class="custom-file-input" id="customFileLang2" lang="es" (change)="handleImage($event)" required>
                  <label id="archivo" class="custom-file-label" for="customFileLang2">Seleccionar Archivo</label>
                </div>
                <div class="col-5">
                  <button type="submit" class="btn btn-primary container" value="Save">Agregar</button>
                </div> 
              </form>
            </div>
            <br>
            <div *ngIf="mostrarExa===true">
              <embed [src]="urlPDF | safe" type="application/pdf" width="100%" height="1200" />
            </div>
          </div>
        </div>      
      </div>
    </div>
  </div>  

  

  