import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { FarmaciaModel } from '../../models/farmacia.model';
import { FarmaciaService } from 'src/app/services/farmacia.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-actualizar-producto',
  templateUrl: './actualizar-producto.component.html',
  styleUrls: ['./actualizar-producto.component.css']
})
export class ActualizarProductoComponent implements OnInit {

  producto: FarmaciaModel = new FarmaciaModel();

  constructor(private farmaciaService: FarmaciaService,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit(): void {

    const id = this.route.snapshot.paramMap.get('id');

    if ( id !== 'nuevo' ) {
      this.farmaciaService.getHeroe( id )
        .subscribe( (resp: FarmaciaModel) => {
          this.producto = resp;
          this.producto.id = id;
        });
    }

  }

  guardar( form: NgForm ) {

    if ( form.invalid ) {
      console.log('Formulario no válido');
      return;
    }

    Swal.fire({
      title: 'Espere',
      text: 'Guardando información',
      icon: 'info',
      allowOutsideClick: false
    });

    Swal.showLoading();
    
    let peticion: Observable<any>;

    if ( this.producto.id ) {
      peticion = this.farmaciaService.actualizarHeroe( this.producto );
    } else {
      peticion = this.farmaciaService.crearHeroe( this.producto );
    }

    peticion.subscribe( resp => {

      Swal.fire({
        title: this.producto.nombre_comercial,
        text: 'Se actualizó correctamente',
        icon: 'success'
      });

    });

    this.router.navigate(['/farmacia/nuevo']);

  }

}
