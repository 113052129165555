import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OptometriaLabModel } from 'src/app/models/optometriaLab.model';
import { PrimeraService } from '../../services/primera.service';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-register-optometria',
  templateUrl: './register-optometria.component.html',
  styleUrls: ['./register-optometria.component.css']
})
export class RegisterOptometriaComponent implements OnInit {

  optometriaLab: OptometriaLabModel = new OptometriaLabModel();
  optometriaLabs: OptometriaLabModel[];

  constructor(private primeraService: PrimeraService, private router: Router) { }

  ngOnInit(): void {
  }

  onRegister() {
    let peticion: Observable<any>;
    peticion = this.primeraService.crearOptometriaLab( this.optometriaLab );
    peticion.subscribe( resp => {
      Swal.fire({
        title: this.optometriaLab.nombre,
        text: 'Se creó correctamente',
        icon: 'success'
      }).then((result) => {
        this.router.navigate(['/login-laboratorio']);
      });
    });
   
  }  

}
