<div class="container">
    <div class="mt-5 pt-5">
        <div id="contenedor" class="p-3 my-5">
            <h1 class="text-center my-3">CONSUTA TUS SERVICIOS</h1>
            <div class="container">
              <h2>Seleccione el tipo de documento</h2>
              <select class="form-control" [(ngModel)]="opcionSeleccionado" (change)="change()">
                <option value="Examen-Laboratorio-1">Examen de laboratorio 1</option>
                <option value="Examen-Laboratorio-2">Examen de laboratorio 2</option>
                <option value="Examen-Audiometria">Examen de Audiometria</option>
                <option value="Examen-Optometria">Examen de Optometria</option>
                <option value="Informe-RayosX-1">Informe de Radiografía 1</option>
                <option value="Informe-RayosX-2">Informe de Radiografía 2</option>
                <option value="Informe-RayosX-3">Informe de Radiografía 3</option>
                <option value="Imagen-RayosX-1">Imagen de Radiografía 1</option>
                <option value="Imagen-RayosX-2">Imagen de Radiografía 2</option>
                <option value="Imagen-RayosX-3">Imagen de Radiografía 3</option>
              </select>
            </div>
            <br><br>
            <div *ngIf="mostrarFrm===true">
              <button type="button" class="btn btn-primary btn-lg btn-block my-3" (click)="downloadBrochure()">DESCARGAR EXAMEN</button> 
              <embed [src]="urlPDF | safe" type="application/pdf" width="100%" height="1200" />

              <!-- <a [href]="urlPDF" download="examen">
                <button type="button">Visualizar pdf</button> 
              </a>  -->

            </div>
        </div>
    </div>
</div>