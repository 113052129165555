<div id="actualizar-pacientes" class="container">
    <div class="row">
        <div class="col-12 p-5">
            <br><br>
            <div id="contenedor" class="bg-secondary p-5">
              <h1>PACIENTE</h1>
              <hr>
              <br>
              <form (ngSubmit)="actualizarPaciente( formPaciente )" #formPaciente="ngForm" >
                <div class="left">
                  <div class="form-group">
                    <label>Nombres</label>
                    <input type="text"
                            class="form-control"
                            placeholder="Nombres"
                            [(ngModel)]="paciente.nombres"
                            name="nombres"
                            id="nombres"
                            required >
                  </div>
                  <div class="form-group">
                    <label>Apellidos</label>
                    <input type="text"
                            class="form-control"
                            [(ngModel)]="paciente.apellidos"
                            name="apellidos"
                            placeholder="Apellidos"
                            id="apellidos"
                            required >
                  </div>
                  <div class="form-group">
                    <label>Cédula</label>
                    <input type="text"
                            class="form-control"
                            [(ngModel)]="paciente.cedula"
                            name="cedula"
                            placeholder="Cédula"
                            id="cedula" 
                            required #buscarTexto>
                  </div>
                  <div class="form-group">
                    <label>Sexo</label>
                    <br>
                    <button *ngIf="paciente.sexo" (click)="paciente.sexo = false" class="btn container bg-white" type="button">Masculino</button>
                    <button *ngIf="!paciente.sexo" (click)="paciente.sexo = true" class="btn container bg-white" type="button">Femenino</button>
                  </div>
                </div>
                <div class="right">
                  <div class="form-group">
                    <label>Dirección</label>
                    <input type="text"
                            class="form-control"
                            [(ngModel)]="paciente.direccion"
                            name="direccion"
                            placeholder="Dirección"
                            required>
                  </div>
                  <div class="form-group">
                    <label>Telefono</label>
                    <input type="text"
                            class="form-control"
                            [(ngModel)]="paciente.telefono"
                            name="telefono"
                            placeholder="Telefono"
                            required>
                  </div>
                  <div class="form-group">
                    <label>Correo</label>
                    <input type="text"
                            class="form-control"
                            [(ngModel)]="paciente.correo"
                            name="correo"
                            placeholder="Correo">
                  </div>
                  <div class="form-group">
                    <label>Fecha de Nacimiento</label>
                    <input type="date"
                            class="form-control"
                            [(ngModel)]="paciente.fecha_nacimiento"
                            name="fecha_nacimiento"
                            placeholder="Fecha de Nacimiento"
                            required>
                  </div>
                </div>
                <br>
                <button type="submit" class="btn btn-primary container">Guardar Cambios</button>
              </form>
              <br><br>
              <label>Formularios</label>
              <div class="row">
                <div class="col">
                  <select class="form-control" [(ngModel)]="opcionSeleccionado" (change)="change()">
                    <option value="f1">ATENCION A NIÑOS</option>
                    <option value="f2">CONSULTA EXTERNA</option>
                    <option value="f3">EMERGENCIA</option>
                    <option value="f4">LABORATORIO CLINICO - SOLICITUD</option>
                    <option value="f5">MATERNA PERINATAL</option>
                    <option value="f6">ODONTOLOGIA</option>
                    <option value="f7">REFERENCIA - CONTRAREFERENCIA</option>
                    <option value="f8">SALUD OCUPACIONAL</option>
                  </select>
                </div>
                <div class="col">
                  <form class="row" [formGroup]="newPostForm" (ngSubmit)="addNewPost(newPostForm.value)">
                    <div class="custom-file col">
                      <input type="file" class="custom-file-input" id="customFileLang" lang="es" formControlName="urlPDF" (change)="handleImage($event)" required>
                      <label id="archivo2" class="custom-file-label" for="customFileLang">Seleccionar Archivo</label>
                    </div>
                    <div class="form-group col-6">
                      <button type="submit" class="btn btn-primary container">Agregar Formulario</button>
                    </div>  
                  </form>
                </div>  
              </div>
            </div>
        </div>
    </div>
</div>
        


