import { Pipe, PipeTransform } from '@angular/core';
import { ArgumentOutOfRangeError } from 'rxjs';

@Pipe({
  name: 'filterDate'
})
export class FilterDatePipe implements PipeTransform {

  transform(value: any, arg: any): any {
    const resultPosts = [];
    for (const heroe of value) {
      if (heroe.fecha_registro.indexOf(arg) > -1) {
        resultPosts.push(heroe);
      };
    };
    return resultPosts; 
  }

}
