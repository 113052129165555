<div id="primera" class="container">
  <div class="row mt-5 ">
    <div class="col-12 mt-5 pt-5">
      <div id="contenedor" class="animated fadeIn faster px-5 py-2" >
        <div>
          <h1 class="text-center">DATOS PERSONALES</h1>
          <hr>
          <form (ngSubmit)="guardar( f )" #f="ngForm" >
            <div class="left">
              <div class="form-group">
                <label>Nombres</label>
                <input type="text"
                        class="form-control"
                        placeholder="Nombres"
                        [(ngModel)]="paciente.nombres"
                        name="nombres"
                        id="nombres"
                        required >
              </div>
              <div class="form-group">
                <label>Apellidos</label>
                <input type="text"
                        class="form-control"
                        [(ngModel)]="paciente.apellidos"
                        name="apellidos"
                        placeholder="Apellidos"
                        id="apellidos"
                        required >
              </div>
              <div class="form-group">
                <label>Cédula</label>
                <input type="text"
                       class="form-control"
                       [(ngModel)]="paciente.cedula"
                       name="cedula"
                       placeholder="Cédula"
                       id="cedula" 
                       required #buscarTexto>
              </div>
              <div class="form-group">
                <label>Sexo</label>
                <br>
                <button *ngIf="paciente.sexo" (click)="paciente.sexo = false" class="btn container bg-white" type="button">Masculino</button>
                <button *ngIf="!paciente.sexo" (click)="paciente.sexo = true" class="btn container bg-white" type="button">Femenino</button>
              </div>
            </div>
            <div class="right">
              <div class="form-group">
                <label>Dirección</label>
                <input type="text"
                       class="form-control"
                       [(ngModel)]="paciente.direccion"
                       name="direccion"
                       placeholder="Dirección"
                       required>
              </div>
              <div class="form-group">
                <label>Telefono</label>
                <input type="text"
                       class="form-control"
                       [(ngModel)]="paciente.telefono"
                       name="telefono"
                       placeholder="Telefono"
                       required>
              </div>
              <div class="form-group">
                <label>Correo</label>
                <input type="text"
                       class="form-control"
                       [(ngModel)]="paciente.correo"
                       name="correo"
                       placeholder="Correo">
              </div>
              <div class="form-group">
                <label>Fecha de Nacimiento</label>
                <input type="date"
                       class="form-control"
                       [(ngModel)]="paciente.fecha_nacimiento"
                       name="fecha_nacimiento"
                       placeholder="Fecha de Nacimiento"
                       required>
              </div>
            </div>
            <button type="submit" class="btn btn-primary container">Agregar Paciente</button>
          </form>
        </div>
        <br>
      </div>      
    </div>
  </div>
</div>





