<div id="actualizar-cie" class="container">
    <div class="row">
        <div class="col-12 p-5">
            <br><br>
            <div id="contenedor" class="bg-secondary p-5">
                <h1>CÓDIGO CIE</h1>
                <hr>
                <br>
                <form (ngSubmit)="guardar( f )" #f="ngForm">
                    <div class="form-group">
                        <label>Codigo</label>
                        <input type="text" class="form-control" placeholder="Codigo" [(ngModel)]="cie.codigo" name="codigo" required>
                    </div>
                    <div class="form-group">
                        <label>Descripción</label>
                        <input type="text" class="form-control" placeholder="Descripción" [(ngModel)]="cie.descripcion" name="descripcion">
                    </div>
                    <br><br>
                    <div class="form-group">
                        <button type="submit" class="btn btn-primary container">Guardar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
