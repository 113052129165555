import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FarmaciaModel } from '../models/farmacia.model';
import { map, delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FarmaciaService {

  private url = 'https://clinica-581fe.firebaseio.com/';

  constructor(private http: HttpClient) { }

  crearHeroe( producto: FarmaciaModel ) {

    return this.http.post(`${ this.url }/productos.json`, producto).pipe(map( (resp: any) => {
      producto.id = resp.name;
      return producto;
    }));

  }

  actualizarHeroe( producto: FarmaciaModel ) {

    const productoTemp = {
      ...producto
    };

    delete productoTemp.id;

    return this.http.put(`${ this.url }/productos/${ producto.id }.json`, productoTemp);

  }

  borrarHeroe( id: string ) {

    return this.http.delete(`${ this.url }/productos/${ id }.json`);

  }


  getHeroe( id: string ) {

    return this.http.get(`${ this.url }/productos/${ id }.json`);

  }


  getHeroes() {
    return this.http.get(`${ this.url }/productos.json`).pipe(map( this.crearArreglo ), delay(0));
  }

  private crearArreglo( productosObj: object ) {

    const productos: FarmaciaModel[] = [];

    Object.keys( productosObj ).forEach( key => {
      const producto: FarmaciaModel = productosObj[key];
      producto.id = key;
      productos.push( producto );
    });

    return productos;

  }

}
