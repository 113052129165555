import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { User } from '../../models/user.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
  });

  constructor(private authSvc: AuthService, private router: Router) { }

  ngOnInit(): void {
  }

  // async onGoogleLogin() {
  //   try {
  //     const user = await this.authSvc.loginGoogle();
  //     if (user) {
  //       this.checkUserIsVerified(user);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  async onLogin() {

    const { email, password } = this.loginForm.value;
    try {
      const user = await this.authSvc.login(email, password);
      if (user) {
        this.checkUserIsVerified(user);
      }else{
        Swal.fire({
          title: 'Error al iniciar sesión',
          text: 'Correo o contraseña incorrectos',
          icon: 'warning'
        });
      }
    } catch (error) {
      console.log(error);
    }

  }

  private checkUserIsVerified(user: User) {

    if (user && user.emailVerified) {
      this.router.navigate(['/primera/nuevo']);
    } else if (user) {
      this.router.navigate(['/primera/nuevo']);
    } else {
      this.router.navigate(['/register']);
    }

  }

}
