<div id="inicio">

  <!--==========================
    Intro Section
  ============================-->
  <section id="intro">
    <div class="intro-container">
      <div id="introCarousel" class="carousel  slide carousel-fade" data-ride="carousel">

        <ol class="carousel-indicators"></ol>

        <div class="carousel-inner" role="listbox">

          <div class="carousel-item active bg-secondary">
            <div class="carousel-background"><img src="../assets/img/1.png" alt=""></div>
          </div>

          <div class="carousel-item">
            <div class="carousel-background"><img src="../assets/img/2.png" alt=""></div>
          </div>

          <div class="carousel-item">
            <div class="carousel-background"><img src="../assets/img/3.png" alt=""></div>
          </div>

        </div>

        <a class="carousel-control-prev" href="#introCarousel" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon fa fa-chevron-left" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>

        <a class="carousel-control-next" href="#introCarousel" role="button" data-slide="next">
          <span class="carousel-control-next-icon fa fa-chevron-right" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>

      </div>
    </div>
  </section><!-- #intro -->

  <main id="main">

    <!--==========================
      About Us Section
    ============================-->
    <section id="about">
      <div class="container">

        <header class="section-header wow bounceInUp">
          <h3>Nosotros</h3>
          <p>El Centro Medico CEMILAD fue creado en Agosto del 2018 y surgió como una idea de brindar servicios de salud para los habitantes que residen en el sector de la Venecia 1 y sus alrededores con los servicios de Medicina General, Laboratorio, Ginecología y Odontología actualmente el centro médico cuenta además con los servicios de salud ocupacional dirigido a empresas. Dermatología, Traumatología, Psicología, Ortodoncia, al cual acuden diferentes pacientes del Sur de Quito y en el marco de la salud ocupacional brindan apoyo a empresas del norte, centro y sur de quito, así también de cantones aledaños.</p>
        </header>

        <div class="row about-cols" id="centrar">

          <div class="col-md-4 wow fadeInLeft" id="hijo">
            <div class="about-col">
              <div class="img">
                <img src="../assets/img/about-mission.png" alt="" class="img-fluid">
                <div class="icon"><i class="fa fa-flag-checkered"></i></div>
              </div>
              <h2 class="title"><a href="#">Nuestra Misión</a></h2>
              <p>
                Prestar servicios de salud con calidad y calidez  cumpliendo con la responsabilidad de prevención, recuperación y rehabilitación integral del paciente, conforme a las políticas de Ministerio de Salud Pública y en el marco de la atención médica asistencial y la salud ocupacional.
              </p>
            </div>
          </div>

          <div class="col-md-4 " data-wow-delay="0.2s" id="hijo">
            <div class="about-col wow fadeInRight">
              <div class="img">
                <img src="../assets/img/about-vision.png" alt="" class="img-fluid">
                <div class="icon"><i class="fa fa-eye"></i></div>
              </div>
              <h2 class="title"><a href="#">Nuestra Visión</a></h2>
              <p>
                Ser reconocidos por la población en general como centro médico accesible y contable, que presta su atención de salud satisfaciendo sus necesidades y expectativas, enmarcados en las normativas legales  vigentes del MSP, utilizando la tecnología y los recursos disponibles  de forma eficiente y transparente.
              </p>
            </div>
          </div>

        </div>

      </div>
    </section><!-- #about -->

    <!--==========================
      Services Section
    ============================-->
    <section id="services">
      
      <div class="container">
       
          <div class="col-lg-8">

            <header class="section-header wow bounceInUp">
              <h3>Horarios de Atención</h3>
              <p>Estos son nuestros horarios de atención en las siguientes especialidades</p>
            </header>
    
            <div class="row">
    
              <div class="col-lg-4 col-md-6 box wow bounceInUp" data-wow-duration="1.4s">
                <div class="icon"><i class="fa fa-clock-o"></i></div>
                <h4 class="title"><a href="">Especialidaes</a></h4>
                <p class="description">Dermatología, Ortodoncia y Salud Ocupacional (Previo citas)</p>
              </div>
              <div class="col-lg-4 col-md-6 box wow bounceInUp" data-wow-duration="1.4s">
                <div class="icon"><i class="fa fa-clock-o"></i></div>
                <h4 class="title"><a href="">Laboratorio Clínico</a></h4>
                <p class="description">08H00 a 12H00 y de  15H00 a 17H00</p>
              </div>
              <div class="col-lg-4 col-md-6 box wow bounceInUp" data-wow-duration="1.4s">
                <div class="icon"><i class="fa fa-clock-o"></i></div>
                <h4 class="title"><a href="">Medicina General</a></h4>
                <p class="description">09H00 a 12H00 y de  16H00 18H30</p>
              </div>
              <div class="col-lg-4 col-md-6 box wow bounceInUp" data-wow-delay="0.1s" data-wow-duration="1.4s">
                <div class="icon"><i class="fa fa-clock-o"></i></div>
                <h4 class="title"><a href="">Obstetricia - Ginecológica</a></h4>
                <p class="description">09H00 a 12H00 y de  14H00 a 17H00</p>
              </div>
              <div class="col-lg-4 col-md-6 box wow bounceInUp" data-wow-delay="0.1s" data-wow-duration="1.4s">
                <div class="icon"><i class="fa fa-clock-o"></i></div>
                <h4 class="title"><a href="">Odontología</a></h4>
                <p class="description">11H30 a 13H30</p>
              </div>
    
            </div>
    
          </div>

        
      </div>
      
    </section><!-- #services -->

    <!--==========================
      Contact Section
    ============================-->
    <section id="contact" class="section-bg">
      <div class="container">
        <div class=" row">
          <div class="col-lg-4"></div>
          <div class="col-lg-8">
            <div class="section-header wow bounceInUp">
              <h3>Contáctanos</h3>
              <p>En caso de que quieras más información o detalles, puedes counicarte con nosotros mediante las siguientes maneras</p>
            </div>
    
            <div class="row contact-info" id="centrar">



              <div class="col-md-4 wow bounceInUp" id="hijo">
                <div class="contact-phone">
                  <i class="fa fa-phone"></i>
                  <h3>Teléfono</h3>
                  <!-- <p><a href="tel:23071052"> 23071052</a></p> -->
                  <p><a href="tel:+593 969176929">+593 969176929</a></p>
                </div>
              </div>
    
              <!-- <div class="col-md-4 wow bounceInUp" id="hijo">
                <div class="contact-email">
                  <i class="fa fa-envelope"></i>
                  <h3>Email</h3>
                  <p><a href="mailto:centromedcemilad@gmail.com">centromedcemilad@gmail.com</a></p>
                </div>
              </div> -->

              <div class="col-md-4 wow bounceInUp" id="hijo">
                <div class="contact-location">
                  <i class="fa fa-map-marker"></i>
                  <h3>Dirección</h3>
                  <!-- <p>La Venecia Primera Etapa</p> 
                  <p>Calle S58B y E4B</p> -->
                  <p>Quito - Ecuador</p>
                </div>
              </div>
    
            </div>
            
          </div>
        </div>
        <br><br><br>
        
      </div>
    </section><!-- #contact -->

  </main>

  <!--==========================
    Footer
  ============================-->
  <footer id="footer">
    <div class="footer-top">
      <div class="container">
        <div class="row" id="centrar2">

          <div class="col-lg-3 col-md-6 footer-info" id="hijo">
            <div id="logoFooter">
              <img src="../assets/img/logo2.png" width="250px" alt="" title="" />
            </div>
            <div id="infoFooter">
              <p>Centro Medico creado en Agosto del 2018, formado con la idea de brindar servicios de salud.</p>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 footer-links" id="hijo">
            <h4>Links Útiles</h4>
            <ul>
              <li><i class="fa fa-angle-right"></i> <a href="https://www.salud.gob.ec/">Ministerio de salud</a></li>
              <li><i class="fa fa-angle-right"></i> <a href="https://www.who.int/es">OMS</a></li>
              <li><i class="fa fa-angle-right"></i> <a href="https://www.gob.ec/arcsa">ARCSA</a></li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-6 footer-contact" id="hijo">
            <h4>Redes Sociales</h4>
            <!-- <p>
              <strong>Teléfono:</strong> 23071052<br>  
              <strong>Celular:</strong> +593 969176929<br>
              <strong>Email:</strong> centromedcemilad@gmail.com<br>
            </p> -->

            <div class="social-links">
              <div>
                <a href="#" class="twitter"><i class="fa fa-twitter"></i></a>
                <a href="#" class="facebook"><i class="fa fa-facebook"></i></a>
                <a href="#" class="instagram"><i class="fa fa-instagram"></i></a>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>

    <div class="container">
      <div class="copyright">
        &copy; Copyright <strong>Cemilad</strong>. Todos los derechos reservados
      </div>
    </div>
  </footer><!-- #footer -->

  <a href="#" class="back-to-top"><i class="fa fa-chevron-up"></i></a>

</div>
