import { Component, OnInit } from '@angular/core';
import { PacienteModel } from 'src/app/models/paciente.model';
import { PrimeraService } from '../../services/primera.service';
import { FormulariosService } from '../../services/formularios.service';
import { ActivatedRoute } from '@angular/router';
import { EmpresaLabModel } from '../../models/empresaLab.model';
import { map } from 'rxjs/operators';
import { FormularioModel } from '../../models/formulario.model';
import { NgForm, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-empresa-online',
  templateUrl: './empresa-online.component.html',
  styleUrls: ['./empresa-online.component.css']
})
export class EmpresaOnlineComponent implements OnInit {
  
  pacientes: PacienteModel[];
  empresa: EmpresaLabModel[];
  nombreEmpresa: string;

  urlPDF: string;
  url: any;
  mostrarFrm=false;
  mostrarEx=true;
  mostrarAu=true;
  mostrarOp=true;
  mostrarRx=true;

  constructor(private primeraService: PrimeraService, 
              private route: ActivatedRoute,
              private readonly formulariosService: FormulariosService,
              private http: HttpClient) { }

  ngOnInit(): void {
    const ruc = this.route.snapshot.paramMap.get('id');
    this.primeraService.getEmpresaLab().subscribe(res => {
      this.empresa = res.filter(item => item.ruc == ruc);
      this.nombreEmpresa = this.empresa[0].nombre;

    });

    this.primeraService.getPacientes().subscribe( resp => {
      this.pacientes = resp;

    });

  }

  async viewPdf(cedula, pdfName){
    this.url =  this.formulariosService.getLabExam(cedula, pdfName);
    await this.url.getDownloadURL().subscribe(url => {
       this.urlPDF =  url;
       if(this.mostrarFrm){
        this.mostrarFrm=false;
      }else{
        this.mostrarFrm=true;
      }
    }, err => {
      this.mostrarFrm=false;
      Swal.fire({
        title: 'Archivo no encontrado',
        text: 'No tiene registrado este archivo',
        icon: 'error',
        allowOutsideClick: false
      });
      return;
    });
  }

  downloadPdf(cedula, pdfName){
    this.url =  this.formulariosService.getLabExam(cedula, pdfName);
    this.url.getDownloadURL().subscribe(url => {
      this.urlPDF = url;
      this.downloadBrochure(url, cedula, pdfName);
    }, err => {
      Swal.fire({
        title: 'Archivo no encontrado',
        text: 'No tiene registrado este archivo',
        icon: 'error',
        allowOutsideClick: false
      });
      return;
    });
  }

  async downloadBrochure(urlPDF, cedula, pdfName) {
    
    try {
      console.log(urlPDF);
      const res = await this.http.get(urlPDF, { responseType: 'blob' }).toPromise();
      this.downloadFile(res, cedula, pdfName);
    } catch (e) {
      console.log(e.body.message);
    }
  }

  downloadFile(data, cedula, pdfName) {
    const url = window.URL.createObjectURL(data);
    const e = document.createElement('a');
    e.href = url;
    e.download = `${pdfName}-${cedula}`;
    document.body.appendChild(e);
    e.click();
    document.body.removeChild(e);
  }




}
