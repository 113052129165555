<nav id="barra-navegacion" class="navbar navbar-expand-lg navbar-dark">
  <a class="navbar-brand pl-5" routerLink="/inicio"><img src="../assets/img/logo.png" width="200px" alt="" title="" /></a>
  <!-- <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarColor01"
      aria-controls="navbarColor01"
      aria-expanded="false"
      aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button> -->
  <div class="collapse navbar-collapse" id="navbarColor01">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active" *ngIf="user$ | async as user">
          <a class="p-3" routerLink="/primera/nuevo">Registro</a>
      </li>
      <li class="nav-item active" *ngIf="user$ | async as user">
          <a class="p-3" routerLink="/subsecuente/nuevo">Historia Clínica</a>
      </li>
      <li class="nav-item active" *ngIf="user$ | async as user">
          <a class="p-3" routerLink="/reporte-diario">Reporte Diario</a>
      </li>
      <li class="nav-item active" *ngIf="user$ | async as user">
          <a class="p-3" routerLink="/laboratorio/nuevo">Laboratorio</a>
      </li>
      <li class="nav-item active" *ngIf="user$ | async as user">
          <a class="p-3" routerLink="/farmacia/nuevo">Receta</a>
      </li>
      <li class="nav-item active" *ngIf="user$ | async as user">
          <a class="p-3" routerLink="/pacientes/nuevo">Pacientes</a>
      </li>
      <li class="nav-item active" *ngIf="user$ | async as user">
        <a class="p-3" routerLink="/cie">Cies</a>
      </li>
      <li class="nav-item active" *ngIf="user$ | async as user">
        <a class="p-3" routerLink="/perfil">Perfil</a>
      </li>
    </ul>
    <ul class="navbar-nav ml-auto " *ngIf="user$ | async as user; else login">
      <li class="nav-item" *ngIf="user">
        <a routerLink="/inicio" class="p-5" (click)="onLogout()">CERRAR SESIÓN</a>
      </li>
    </ul>
    <ng-template #login>
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a class="p-4" routerLink="/login-laboratorio">SERVICIOS EN LINEA</a>
          <a class="p-4" routerLink="/login">INICIAR SESIÓN</a>
        </li>
      </ul>
    </ng-template>
    <div *ngIf="cedula">
        <a routerLink="/inicio" class="p-5" >CERRAR SESIÓN</a>
    </div>
  </div>  
</nav>
