<div id="pacientes" class="container">
  <div class="row mt-5">
    <div class="pt-5 my-5">
      <div id="contenedor" class="animated fadeIn faster p-4">
        <form class="container">
          <input class="form-control mr-sm-2" name="buscarLetra" type="text" placeholder="Buscar paciente"  #buscarTexto [(ngModel)]="buscarLetra">
        </form>
        <br>
        <button (click)="exportTableToExcel()" class="btn btn-primary container d-flex ">Exportar a Excel</button>
        <table id='tablaPacientes' class="table mt-3 animated fadeIn faster bg-secondary table-hover">
          <thead class="thead-dark">
            <tr>
              <th scope="col">Nombres</th>
              <th scope="col">Apellidos</th>
              <th scope="col">Cedula</th>
              <th scope="col">Sexo</th>
              <th scope="col">Direccion</th>
              <th scope="col">Telefono</th>
              <th scope="col">Correo</th>
              <th scope="col">Edad</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let paciente of pacientes | filter:buscarLetra">
              <td>{{ paciente.nombres }}</td>
              <td>{{ paciente.apellidos }}</td>
              <td>{{ paciente.cedula }}</td>
              <td>
                  <label *ngIf="paciente.sexo" class="badge badge-success">Masculino</label>
                  <label *ngIf="!paciente.sexo" class="badge badge-danger">Femenino</label>
              </td>
              <td>{{ paciente.direccion }}</td>
              <td>{{ paciente.telefono }}</td>
              <td>{{ paciente.correo }}</td>
              <td>{{ paciente.fecha_nacimiento | edad }}</td>
              <td>
                <div class="row">
                  <button class="btn btn-info p-1 m-1" [routerLink]="['/actualizar-paciente', paciente.id ]">
                    <i class="fa fa-pencil"></i>
                  </button>
                  <button class="btn btn-danger p-1 m-1" (click)="borrarPaciente( paciente, i ) ">
                    <i class="fa fa-trash"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>      
    </div>
  </div>
</div>
