import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ClinicaLabModel } from 'src/app/models/clinicaLab.model';
import { PrimeraService } from '../../services/primera.service';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-register-laboratorio',
  templateUrl: './register-laboratorio.component.html',
  styleUrls: ['./register-laboratorio.component.css']
})
export class RegisterLaboratorioComponent implements OnInit {
  
  clinicaLab: ClinicaLabModel = new ClinicaLabModel();
  clinicaLabs: ClinicaLabModel[];



  constructor(private primeraService: PrimeraService, private router: Router) { }

  ngOnInit(): void {
  }

  // registerForm = new FormGroup({
  //   email: new FormControl(''),
  //   password: new FormControl(''),
  //   displayName: new FormControl(''),
  //   cedula: new FormControl(''),
  //   especialidad: new FormControl(''),
  // });

  onRegister() {
    let peticion: Observable<any>;
    peticion = this.primeraService.crearClinicaLab( this.clinicaLab );
    peticion.subscribe( resp => {
      Swal.fire({
        title: this.clinicaLab.nombre,
        text: 'Se creó correctamente',
        icon: 'success'
      }).then((result) => {
        this.router.navigate(['/login-laboratorio']);
      });
    });
   
  }

}
