import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { EmpresaLabModel } from 'src/app/models/empresaLab.model';
import { PrimeraService } from '../../services/primera.service';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-register-empresa',
  templateUrl: './register-empresa.component.html',
  styleUrls: ['./register-empresa.component.css']
})
export class RegisterEmpresaComponent implements OnInit {

  empresaLab: EmpresaLabModel = new EmpresaLabModel();

  constructor(private primeraService: PrimeraService, private router: Router) { }

  ngOnInit(): void {
  }

  onRegister() {
    let peticion: Observable<any>;
    peticion = this.primeraService.crearEmpresaLab( this.empresaLab );
    peticion.subscribe( resp => {
      Swal.fire({
        title: this.empresaLab.nombre,
        text: 'Se creó correctamente',
        icon: 'success'
      }).then((result) => {
        this.router.navigate(['/login-laboratorio']);
      });
    });
   
  }

}
