<div class="container">
    <div class="mt-5 pt-5">
        <div id="contenedor" class="p-3 my-5">
            <div class="col">
                <h1 class="text-center">Examen de Optometría</h1>
                <form (ngSubmit)="guardar( f )" #f="ngForm">
                    <h2>Ingrese la cedula del paciente</h2>
                    <input type="text"
                        class="form-control"
                        placeholder="Cedula"
                        [(ngModel)]="paciente.cedula"
                        name="nombres"
                        id="nombres"
                        required >
                    <br>
                    <h2>Ingrese la Empresa/Laboratorio</h2>
                    <select class="form-control"  [(ngModel)]="paciente.empresa" name="empresa" id="empresa" required>
                        <option value="undefined" hidden selected>Selecciona una Empresa o Laboratorio</option>
                        <option *ngFor="let empresa of empresas" [ngValue]="empresa.nombre">{{empresa.nombre}}</option>
                    </select>
                    <br>
                    <br>
                    <form [formGroup]="newPostForm">
                        <h2>Subir examen de Optometría</h2>
                        <div class="">
                            <div class="custom-file col-13">
                                <input type="file" class="custom-file-input" id="customFileLang" lang="es" formControlName="PDF" (change)="showPDF($event)" required>
                                <label id="archivo" class="custom-file-label" for="customFileLang" required>Seleccionar Archivo</label>
                            </div>
                        </div>
                    </form>
                    <button type="submit" class="btn btn-primary btn-lg btn-block mt-5">Guardar</button>
                    <br>
                </form>
            </div>
        </div>
    </div>
</div>

