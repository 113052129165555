<div id="farmacia" class="container">
    <div class="row mt-5">
        <div class="col-12 my-5">
            <br><br>    
            <div id="contenedor" class="p-5 mb-3 bg-white">
              <div class="row">
                <div class="col">
                  <br><br><br><br><br><br>
                  <datalist id="productos">
                    <option *ngFor="let producto of productos" value="{{ producto.nombre_comercial }} ({{ producto.nombre_generico }}) {{ producto.dosis }} ({{ producto.presentacion }})">
                  </datalist>
                  <datalist id="cantidades">
                    <option value="Cantidad: 1">
                    <option value="Cantidad: 2">
                    <option value="Cantidad: 3">
                    <option value="Cantidad: 4">
                    <option value="Cantidad: 5">
                    <option value="Cantidad: 6">
                    <option value="Cantidad: 7">
                    <option value="Cantidad: 8">
                    <option value="Cantidad: 9">
                    <option value="Cantidad: 10">
                    <option value="Cantidad: 11">
                    <option value="Cantidad: 12">
                    <option value="Cantidad: 13">
                    <option value="Cantidad: 14">
                    <option value="Cantidad: 15">
                    <option value="Cantidad: 16">
                    <option value="Cantidad: 17">
                    <option value="Cantidad: 18">
                    <option value="Cantidad: 19">
                    <option value="Cantidad: 20">
                  </datalist>
                  <datalist id="veces">
                    <option value="INDICACIONES:  Una vez al día">
                    <option value="INDICACIONES:  2 veces al día">
                    <option value="INDICACIONES:  3 veces al día">
                    <option value="INDICACIONES:  4 veces al día">
                    <option value="INDICACIONES:  5 veces al día">
                    <option value="INDICACIONES:  Cada 4 horas">
                    <option value="INDICACIONES:  Cada 6 horas">
                    <option value="INDICACIONES:  Cada 8 horas">
                    <option value="INDICACIONES:  Cada 12 horas">
                    <option value="INDICACIONES:  Cada día">
                  </datalist>
                  <datalist id="dias">
                    <option value="por 1 día">
                    <option value="por 2 días">
                    <option value="por 4 días">
                    <option value="por 5 días">
                    <option value="por 6 días">
                    <option value="por 7 días">
                    <option value="por 8 días">
                    <option value="por 9 días">
                    <option value="por 10 días">
                    <option value="por 11 días">
                    <option value="por 12 días">
                    <option value="por 13 días">
                    <option value="por 14 días">
                    <option value="por 15 días">
                    <option value="por 16 días">
                    <option value="por 17 días">
                    <option value="por 18 días">
                    <option value="por 19 días">
                    <option value="por 20 días">
                  </datalist>
                  <datalist id="vias">
                    <option value="Vía Oral">
                    <option value="Vía Nasal">
                    <option value="Vía Ótica">
                    <option value="Vía Ocular">
                    <option value="Vía Tópica">
                    <option value="Vía Sublingual">
                    <option value="Vía Rectal">
                    <option value="Vía Parenteral">
                    <option value="Vía Vaginal">
                    <option value="Vía Transdérmica">
                  </datalist>
                  <label>Cantidad de Medicamentos:</label>
                  <select class="form-control" [(ngModel)]="medSeleccionado"  (change)="changeMed()">
                    <option value="m1">1 Medicamento</option>
                    <option value="m2">2 Medicamentos</option>
                    <option value="m3">3 Medicamentos</option>
                    <option value="m4">4 Medicamentos</option>
                    <option value="m5">5 Medicamentos</option>
                  </select>
                  <br>
                  <button (click)="downloadPDF()" class="btn btn-primary container">Descargar Receta</button>
                </div>
                <div class="col-7">
                  <div id="imprimir" #imprimir class="pl-5">
                    <br>
                    <div class="text-right">
                      <img src="../assets/img/logo-certificado-medico.png" width="30%">
                    </div>
                    <h1></h1>
                    <p class="text-right">Quito, <span id="fechas" #fec></span></p>
                    <h3 class="text-center"><strong>RECETA MÉDICA</strong></h3>
                    <br>
                    <div *ngIf="med1 == true">
                      <label><strong>Medicamento 1:</strong></label>
                      <div class="row pl-3">
                        <input type="text" id="producto" class="col-8" placeholder="Producto" list="productos">
                        <input type="text" id="cantidad" class="col" placeholder="Cantidad" list="cantidades">  
                      </div>
                      <div class="row pl-3">
                        <input type="text" id="frecuencia" class="col-4"  placeholder="Frecuencia" list="veces">
                        <input type="text" id="duracion" class="col-2" placeholder="Duración" list="dias">
                        <input type="text" id="via" class="col-2" placeholder="Vía" list="vias">
                        <input type="text" id="otros" class="col" placeholder="Otros"> 
                      </div>
                    </div>
                    <br>
                    <div *ngIf="med2 == true">
                      <label><strong>Medicamento 2:</strong></label>
                      <div class="row pl-3">
                        <input type="text" id="producto" class="col-8" placeholder="Producto" list="productos">
                        <input type="text" id="cantidad" class="col" placeholder="Cantidad" list="cantidades">  
                      </div>
                      <div class="row pl-3">
                        <input type="text" id="frecuencia" class="col-4"  placeholder="Frecuencia" list="veces">
                        <input type="text" id="duracion" class="col-2" placeholder="Duración" list="dias">
                        <input type="text" id="via" class="col-2" placeholder="Vía" list="vias">
                        <input type="text" id="otros" class="col" placeholder="Otros"> 
                      </div>
                    </div>
                    <br>
                    <div *ngIf="med3 == true">
                      <label><strong>Medicamento 3:</strong></label>
                      <div class="row pl-3">
                        <input type="text" id="producto" class="col-8" placeholder="Producto" list="productos">
                        <input type="text" id="cantidad" class="col" placeholder="Cantidad" list="cantidades">  
                      </div>
                      <div class="row pl-3">
                        <input type="text" id="frecuencia" class="col-4"  placeholder="Frecuencia" list="veces">
                        <input type="text" id="duracion" class="col-2" placeholder="Duración" list="dias">
                        <input type="text" id="via" class="col-2" placeholder="Vía" list="vias">
                        <input type="text" id="otros" class="col" placeholder="Otros"> 
                      </div>
                    </div>
                    <br>
                    <div *ngIf="med4 == true">
                      <label><strong>Medicamento 4:</strong></label>
                      <div class="row pl-3">
                        <input type="text" id="producto" class="col-8" placeholder="Producto" list="productos">
                        <input type="text" id="cantidad" class="col" placeholder="Cantidad" list="cantidades">  
                      </div>
                      <div class="row pl-3">
                        <input type="text" id="frecuencia" class="col-4"  placeholder="Frecuencia" list="veces">
                        <input type="text" id="duracion" class="col-2" placeholder="Duración" list="dias">
                        <input type="text" id="via" class="col-2" placeholder="Vía" list="vias">
                        <input type="text" id="otros" class="col" placeholder="Otros"> 
                      </div>
                    </div>
                    <br>
                    <div *ngIf="med5 == true">
                      <label><strong>Medicamento 5:</strong></label>
                      <div class="row pl-3">
                        <input type="text" id="producto" class="col-8" placeholder="Producto" list="productos">
                        <input type="text" id="cantidad" class="col" placeholder="Cantidad" list="cantidades">  
                      </div>
                      <div class="row pl-3">
                        <input type="text" id="frecuencia" class="col-4"  placeholder="Frecuencia" list="veces">
                        <input type="text" id="duracion" class="col-2" placeholder="Duración" list="dias">
                        <input type="text" id="via" class="col-2" placeholder="Vía" list="vias">
                        <input type="text" id="otros" class="col" placeholder="Otros"> 
                      </div>
                    </div>
                    <br>
                    <p>{{ usuario.displayName }}<br>
                      {{ usuario.especialidad }}<br>
                      Código médico: {{ usuario.cedula }}<br>
                      Correo electrónico: {{ usuario.email }}
                    </p>
                    <h1></h1>
                    <div class="text-center">
                      <img src="../assets/img/footer.png" width="100%">
                    </div>
                  </div>
                </div>
              </div>
              <br><br>
              <button (click)="verProductos()" class="btn btn-primary container">Ver Medicamentos</button>
              <div *ngIf="verProd===true">
                <br><br>
                <div class="row container">
                  <div>
                    <h1>Lista de Medicamentos</h1>    
                  </div>
                  <div class="ml-auto">
                    <button class="btn btn-primary" [routerLink]="['/actualizar-producto', 'nuevo']">Agregar Medicamento</button>
                  </div>
                </div>
                <table class="table mt-3 animated fadeIn faster bg-secondary table-hover" >
                  <thead class="thead-dark">
                    <tr>
                      <th scope="col">Nombre Comercial</th>
                      <th scope="col">Nombre Genérico</th>
                      <th scope="col">Dosis</th>
                      <th scope="col">Presentaciones</th>
                      <th scope="col">Tools</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let producto of productos">
                      <td>{{ producto.nombre_comercial }}</td>
                      <td>{{ producto.nombre_generico }}</td>
                      <td>{{ producto.dosis }}</td>
                      <td>{{ producto.presentacion }}</td>
                      <td>
                        <div class="row">
                          <button class="btn btn-info p-1 m-1" [routerLink]="['/actualizar-producto', producto.id ]">
                            <i class="fa fa-pencil"></i>
                          </button>
                          <button class="btn btn-danger p-1 m-1" (click)="borrarProducto( producto, i ) ">
                            <i class="fa fa-trash"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
        </div>
    </div>
</div> 
