import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CieModel } from '../models/cie.model';
import { map, delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CieService {

  private url = 'https://clinica-581fe.firebaseio.com/';

  constructor(private http: HttpClient) { }

  crearCie( cie: CieModel ) {

    return this.http.post(`${ this.url }/cies.json`, cie).pipe(map( (resp: any) => {
      cie.id = resp.name;
      return cie;
    }));

  }

  actualizarCie( cie: CieModel ) {

    const cieTemp = {
      ...cie
    };

    delete cieTemp.id;

    return this.http.put(`${ this.url }/cies/${ cie.id }.json`, cieTemp);

  }

  borrarCie( id: string ) {

    return this.http.delete(`${ this.url }/cies/${ id }.json`);

  }


  getCie( id: string ) {

    return this.http.get(`${ this.url }/cies/${ id }.json`);

  }


  getCies() {
    return this.http.get(`${ this.url }/cies.json`).pipe(map( this.crearArreglo ), delay(0));
  }

  private crearArreglo( ciesObj: object ) {

    const cies: CieModel[] = [];

    Object.keys( ciesObj ).forEach( key => {
      const cie: CieModel = ciesObj[key];
      cie.id = key;
      cies.push( cie );
    });

    return cies;

  }

}
