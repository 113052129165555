import { Component, OnInit } from '@angular/core';
import { SubsecuenteModel } from '../../models/subsecuente.model';
import { SubsecuenteService } from '../../services/subsecuente.service';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-actualizar-evolucion',
  templateUrl: './actualizar-evolucion.component.html',
  styleUrls: ['./actualizar-evolucion.component.css']
})
export class ActualizarEvolucionComponent implements OnInit {

  subsecuente: SubsecuenteModel = new SubsecuenteModel();

  constructor(private subsecuenteService: SubsecuenteService,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit(): void {

    const id = this.route.snapshot.paramMap.get('id');

    if ( id !== 'nuevo' ) {
      this.subsecuenteService.getHeroe( id ).subscribe( (resp: SubsecuenteModel) => {
        this.subsecuente = resp;
        this.subsecuente.id = id;
      });
    }

  }

  guardar( form: NgForm ) {

    if ( form.invalid ) {
      console.log('Formulario no válido');
      return;
    }

    Swal.fire({
      title: 'Espere',
      text: 'Guardando información',
      icon: 'info',
      allowOutsideClick: false
    });

    Swal.showLoading();

    let peticion: Observable<any>;

    if ( this.subsecuente.id ) {
      peticion = this.subsecuenteService.actualizarHeroe( this.subsecuente );
    } else {
      peticion = this.subsecuenteService.crearHeroe( this.subsecuente );
    }

    peticion.subscribe( resp => {

      Swal.fire({
        title: 'Evolución',
        text: 'Se actualizó correctamente',
        icon: 'success'
      });

    });

    this.router.navigate(['/subsecuente/nuevo']);

  }

}
