<div id="actualizar-producto" class="container">
    <div class="row">
        <div class="col-12 p-5">
            <br><br>
            <div id="contenedor" class="bg-secondary p-5">
                <form (ngSubmit)="guardar( f )" #f="ngForm">
                    <h1>MEDICAMENTO</h1>
                    <hr>
                    <br>
                    <div class="form-group">
                        <label>Nombre Comercial</label>
                        <input type="text"
                            class="form-control"
                            placeholder="Nombre Comercial"
                            [(ngModel)]="producto.nombre_comercial"
                            name="nombre_comercial"
                            required>
                    </div>
                    <div class="form-group">
                        <label>Nombre Genérico</label>
                        <input type="text"
                            class="form-control"
                            placeholder="Nombre Genérico"
                            [(ngModel)]="producto.nombre_generico"
                            name="nombre_generico"
                            required>
                    </div>
                    <div class="form-group">
                        <label>Dosis</label>
                        <input type="text"
                            class="form-control"
                            [(ngModel)]="producto.dosis"
                            name="dosis"
                            placeholder="Dosis">
                    </div>
                    <div class="form-group">
                        <label>Prentaciones</label>
                        <input type="text"
                            class="form-control"
                            [(ngModel)]="producto.presentacion"
                            name="prentacion"
                            placeholder="Prentaciones">
                    </div>
                    <br><br>
                    <div class="form-group">
                        <button type="submit" class="btn btn-primary container">Guardar</button>
                    </div> 
                </form>
            </div>
        </div>
    </div>
</div>
        



