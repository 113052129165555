import { Component, ViewChild, OnInit, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { jsPDF } from "jspdf";
import { PrimeraService } from '../../services/primera.service';
import { PrimeraModel } from '../../models/primera.model';
import { CieModel } from '../../models/cie.model';
import { CieService } from '../../services/cie.service';
import { User } from '../../models/user.model';
import { Observable } from 'rxjs';
import html2canvas from 'html2canvas';
import * as moment from 'moment';

@Component({
  selector: 'app-certificado-medico',
  templateUrl: './certificado-medico.component.html',
  styleUrls: ['./certificado-medico.component.css']
})
export class CertificadoMedicoComponent implements OnInit {

  public user$: Observable<User> = this.authSvc.afAuth.user;
  
  paciente: PrimeraModel = new PrimeraModel();
  cies: CieModel[];
  usuario: User;

  codigoDiagnostico: string;
  diasReposo: string;
  diaDesde: string;
  mesDesde: string;
  yearDesde: string;
  diaHasta: string;
  mesHasta: string;
  yearHasta: string;

  constructor(private primeraService: PrimeraService,
              private route: ActivatedRoute,
              private router: Router,
              private cieService: CieService,
              public authSvc: AuthService) { }

  ngOnInit(): void {

    this.user$.subscribe(user => {
    
      this.authSvc.getUser( user.uid ).subscribe( (resp: User) => {
        this.usuario = resp;
        this.usuario.uid =user.uid;
      });
    
    });
     
    const id = this.route.snapshot.paramMap.get('id');

    if ( id !== 'nuevo' ) {
      this.primeraService.getHeroe( id ).subscribe( (resp: PrimeraModel) => {
        this.paciente = resp;
        this.paciente.id = id;
      });
    }

    this.cieService.getCies()
      .subscribe( resp => {
      this.cies = resp;
    });

    moment.locale('es');
    var nowDate = moment(new Date());
    var Fecha = document.querySelector("#fechas");
    Fecha.textContent = nowDate.format('dddd DD [de] MMMM [de] YYYY');

  }

  @ViewChild('content') content: ElementRef;
  public downloadPDF(){
    
    window["html2canvas"] = html2canvas;
    var doc = new jsPDF("p","pt","A4");

    doc.html(document.getElementById('imprimir') , {
       callback: function (doc) {
         doc.save();
       }
    });
    
  }

  mostrarDatos(){
    document.getElementById("codigoD").innerHTML=this.codigoDiagnostico;
    document.getElementById("diasR").innerHTML=this.diasReposo;
    document.getElementById("diaD").innerHTML=this.diaDesde;
    document.getElementById("mesD").innerHTML=this.mesDesde;
    document.getElementById("yearD").innerHTML=this.yearDesde;
    document.getElementById("diaH").innerHTML=this.diaHasta;
    document.getElementById("mesH").innerHTML=this.mesHasta;
    document.getElementById("yearH").innerHTML=this.yearHasta;
  }

}
