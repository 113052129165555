import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterEmpresa'
})
export class FilterEmpresaPipe implements PipeTransform {

  transform(value: any, arg: any): any {
    if (arg[0] === '' || arg[0].length < 1) return value;
    const resultPosts = [];
    for (const paciente of value) {
      if (paciente.empresa.toLowerCase() === arg[0].toLowerCase()) {
        resultPosts.push(paciente);
      };
    };
    return resultPosts;
  }
}
